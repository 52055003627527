import styled from "styled-components";
import { tokens } from "../css/tokens";

export const Quote = styled.aside`
  display: inline-block;
  margin: ${tokens.spacing.s4} 0;
  border-top: 1px solid ${tokens.colors.grayLight};
  border-bottom: 1px solid ${tokens.colors.grayLight};
  padding: ${tokens.spacing.s4};
  font-style: italic;
  color: ${tokens.colors.gray};
  text-align: center;

  &::before, &::after{
    content: ' \" ';
    font-weight: ${tokens.fontWeight.bold};
    font-size: ${tokens.fontSize.large};
    line-height: 0;
  }
`
