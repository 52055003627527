import React from "react";
import groupImg from "../../assets/JM_group.jpg";
import bannerImg from "../../assets/banner2.jpg";
import { useHistory } from "react-router-dom";

import { Section, Container, Column6, Column5 } from "../Elements";
import { Button } from "../Form";
import { Quote } from "../Quote";
import { Banner } from "../Banner";

const About = () => {
  let history = useHistory();

  return (
    <Section style={{ backgroundColor: "#fff" }} id="about">
      <Container className={"flex-md"} style={{ padding: "4rem 0" }}>
        <Column5>
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "30px",
              color: "#000",
            }}
          >
            About us
          </h2>
          <p>
            Juicy Melons is a Dutch business founded by 6 enthousiastic Sur-Ron
            owners who will strive for perfection and nothing less when it comes
            to their favourite hobby; riding the Sur-Ron. So why not make our
            improvements accessible for everyone to enjoy?
          </p>
          <p>
            Our mission is to start a new era in the Sur-Ron parts industry and
            to make new parts and existing parts better, easier to mount and way
            more sophisticated than ever before.
          </p>
          <p>
            All of our products have been severely tested under the most extreme
            circumstances and come with an easy how-to video to make the
            installation process as simple and fast as possible.
          </p>
          <p>
            We are a group of friends who each have our own terrain of
            expertise; 2 IT-experts, 2 Engineers, a Salesman and a professional
            photographer/filmmaker. Because we’ve got years experience in our
            own field we can use these skills to create a combination of
            something beautiful, almost as beautiful as Juicy Melons.
          </p>
        </Column5>
        <Column6 className={"offset-lg"} style={{ height: "100%" }}>
          <img
            alt="groupImg"
            src={groupImg}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Column6>
      </Container>
      <Container className={"flex-md"} style={{ padding: "4rem 0" }}>
        <Column6 className={"order-last"} style={{ height: "100%" }}>
          <img
            alt="banner"
            src={bannerImg}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Column6>
        <Column5 className={"offset-lg"}>
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "30px",
              color: "#000",
            }}
          >
            Why the name "Juicy Melons"
          </h2>
          <p>
            We were looking for a catchy name with a sense of humour while still
            keeping <i>some</i> connection to the products we sell.
          </p>
          <p>
            'Juice' is a term which can be also used for electrical power.
            Something that is undeniably connected to the electric bikes market.
            <br />
            In addition, 'Juicing' something up can refer to upgrading /
            customizing. Which clearly desribes the product range that we offer.
            <br />
          </p>
          <Quote>We don't just make it better, we make it Juicy!</Quote>
          <p>
            As for the 'Melons' part, what can we say? Who doesn't like Melons!
          </p>
          <Button
            className={"primary-new"}
            onClick={() => history.push("/shop")}
          >
            <span>VISIT OUR SHOP NOW</span>
          </Button>
        </Column5>
      </Container>
      <Banner
        title={"A product for every wish"}
        txt={
          "Ready to elevate your Sur Ron e-bike experience? Click below to see all our products. Upgrade your Sur-Ron today and experience the difference!"
        }
        buttonTxt={"Our products"}
      ></Banner>
    </Section>
  );
};

export { About };
