import styled, { css } from "styled-components";
import { tokens } from "../css/tokens";

export const RadioButton = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 1.6rem;
  display: flex;
  position: relative;

  input[type=radio]{
    display: none;

    &:checked{
      + label{
        font-weight: 700;
        border-color: ${tokens.colors.green};
      }
    }
  }

  label{
    display: flex;
    align-items: center;
    padding: 1.2rem 1.6rem;
    cursor: pointer;
    flex: 1;

    ${(props) =>
    props.stretched
      ? css`
        &::after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      `
      :
      ''}
        
    ${(props) =>
    props.stretched ? css`
      &:hover::after{
        box-shadow: 0 0 6px rgba(0,0,0,.2);
      }`
      :
      `
      &:hover{
        box-shadow: 0 0 6px rgba(0,0,0,.2);
      }
    `}


    img{
      margin-right: 1.2rem;
      width: 2.4rem;
    }
  }

  select, .select{
    font-size: 1.4rem;
    border: 0;
    border-left: 1px solid #ddd;
    padding: 0 1.6rem;
    display: flex;
    align-items: center;
  }
  select:hover{
    cursor: pointer;
    box-shadow: 0 0 6px rgba(0,0,0,.2);
  }
`