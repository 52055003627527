export const tokens = {
  breakpoints: {
    xsmall: '480px',
    small: '576px',
    medium: '768px',
    large: '992px',
  },
  spacing: {
    s2: '0.4rem',
    s3: '0.8rem',
    s4: '1.6rem',
    s5: '3.2rem',
    s6: '4.8rem',
    s7: '6.4rem',
    s8: '9.6rem',
  },
  colors: {
    pink: '#FE7796',
    green: '#22DC20',
    text: '#303030',
    gray: '#aaa',
    invert: '#fff',
    white: '#fff',
    grayLight: '#ddd',
  },
  fontSize: {
    smallest: '1.2rem',
    small: '1.4rem',
    medium: '1.6rem',
    large: '1.8rem',
  },
  fontWeight: {
    normal: 400,
    bold: 700,
  }
}
