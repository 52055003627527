import React, { useState } from "react";
import styled from "styled-components";
import { tokens } from "../css/tokens";

import { NotificationDot } from "./Dot";
import { CheckoutButton } from "./ProductFunctions";
import { FormatPrice, Price } from "./Price";
import { Icon } from "./Icon";
import { Backdrop } from "./Modal";

import { Link } from "react-router-dom";

const StyledCartItem = styled.div`
  border-bottom: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  div {
    margin-right: 1rem;
    font-size: 1.6rem;
    max-width: 30rem;
    flex: 1 1 auto;
    text-align: left;
    &:last-of-type {
      margin-right: 0;
      flex: 0 0 60px;
      white-space: nowrap;
    }
    small {
      color: ${tokens.colors.gray};
      display: flex;
      justify-content: space-between;
    }
  }
  img {
    max-height: 4rem;
    margin-right: 1rem;
    border-radius: 4px;
  }
`;

const CartOverflow = styled.div`
  overflow: auto;
  display: block;
  padding: 2rem 2rem 2rem 8rem;
`;

const CartFooter = styled.div`
  padding: 2rem 2rem 2rem 8rem;
  background-color: #393939;
  bottom: 0;
  position: absolute;
  width: 100%;
  @media screen and (min-width: ${tokens.breakpoints.medium}) {
    padding: 2rem;
  }
`;

const StyledCart = styled.div`
  order: 1;
  background-color: #303030;
  position: fixed;
  color: #fff;
  top: 0;
  bottom: 0;
  width: 100vw;
  font-family: "Saira Condensed", "Saira", sans-serif;
  font-weight: 300;
  z-index: 15;
  text-align: right;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  justify-content: flex-end;
  padding-bottom: 123px;
  right: -100vw;
  transition: right 0.3s, margin-right 0.3s;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 2.4rem;
  }
  li a {
    color: #b8b8b8;
    text-decoration: none;
    border-right: 1px solid #4e4e4e;
    padding: 3px 2rem 3px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  li a svg {
    height: 2rem;
    margin-left: 0.8rem;
  }
  li a:hover,
  li.active a {
    border-right: 1px solid currentColor;
  }
  .menu_toggle {
    position: fixed;
    bottom: 10rem;
    height: 6rem;
    right: -6rem;
    display: flex;
    z-index: 10;
    cursor: pointer;
    transition: right 0.3s;
    border: 1px solid #d4d4d4;
  }
  .menu_toggle span {
    width: 6rem;
    height: 100%;
    background-color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .menu_toggle span svg {
    height: 2.4rem;
  }
  .menu_toggle span.toggle--close svg {
    width: 1.6rem;
    margin-left: -1px;
  }
  @media screen and (min-width: 768px) {
    .menu_toggle {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @media screen and (hover: hover) {
    &:hover {
      margin-right: 4rem;
      .menu_toggle {
        right: -2rem;
      }
    }
  }
  @media screen and (min-width: ${tokens.breakpoints.xsmall}) {
    justify-content: center;
    width: var(--drawerDesktop);
    right: calc(var(--drawerDesktop) * -1);
  }
  &::before {
    content: "";
    position: absolute;
    right: 100%;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: #000;
    z-index: 5;
    transition: opacity 0.3s;
    pointer-events: none;
  }
  &.open {
    z-index: 20;
    right: 0;
    margin-right: 0;
    .menu_toggle {
      right: calc(100vw - 6rem);
      @media screen and (min-width: ${tokens.breakpoints.xsmall}) {
        right: calc(var(--drawerDesktop) - 6rem);
      }
    }
  }
`;

const LegalText = styled.div`
  font-family: "Montserrat", "Arial", sans-serif;
  display: flex;
  text-align: left;
  margin-top: 8px;
  label {
    font-size: 1.1rem;
  }
  input[type="checkbox"] {
    margin: 0 4px 0 0;
    height: 2.4rem;
    width: 2.4rem;
  }
`;

const CartItem = ({ product, functions, index, currency }) => {
  const display_name = product.price_data.product_data.name.split(" - ")[0];
  const options = !!product.price_data.product_data.name.split(" - ")[1]
    ? product.price_data.product_data.name.split(" - ").map((opt, index) => {
        if (index === 1) {
          return `${opt} `;
        } else if (index > 1) {
          return `/ ${opt} `;
        } else {
          return false;
        }
      })
    : "";

  let totalProdQuantityPrice = {
    [`unit_amount_${currency}`]:
      product.quantity *
      product.price_data.unit_amount[`unit_amount_${currency}`],
  };

  return (
    <StyledCartItem>
      <img src={`${product.price_data.product_data.images[0]}`} alt="img" />
      <div className="description">
        {display_name}
        <br />
        <small>
          <span>{options}</span>
          {product.quantity}x
        </small>
      </div>
      <Price
        value={totalProdQuantityPrice}
        smallText={
          <FormatPrice
            value={product.price_data.unit_amount}
            currency={currency}
          />
        }
        currency={currency}
      />
      <Icon
        onClick={() => functions.remove(index)}
        interactive
        style={{ marginLeft: "1.2rem" }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            fill="currentColor"
            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
          />
        </svg>
      </Icon>
    </StyledCartItem>
  );
};

const Cart = ({ cart, functions, toggleDrawer, cartState, locale }) => {
  const [terms, acceptTerms] = useState(false);
  const cartTotal = (content, currency, type) => {
    let totalPrice = { [`unit_amount_${currency}`]: 0 };
    let totalQuantity = 0;
    for (let i = 0; i < content.length; i++) {
      totalPrice[`unit_amount_${currency}`] +=
        content[i].quantity *
        content[i].price_data.unit_amount[`unit_amount_${currency}`];
      totalQuantity += content[i].quantity;
    }
    if (type === "quantity") {
      return totalQuantity;
    } else {
      return <FormatPrice value={totalPrice} currency={currency} />;
    }
  };

  return (
    <>
      <StyledCart className={[`cart`, cartState ? "open" : undefined]}>
        <span
          className="menu_toggle"
          id="toggle"
          onClick={() => toggleDrawer("cart")}
        >
          <span className="toggle--open">
            {cart.products.length ? (
              <NotificationDot>
                {cartTotal(cart.products, locale.currency, "quantity")}
              </NotificationDot>
            ) : (
              ""
            )}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path
                fill="currentColor"
                d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z"
              />
            </svg>
          </span>
          <span className="toggle--close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
              />
            </svg>
          </span>
        </span>
        <CartOverflow>
          <div>
            {cart.products.length ? (
              cart.products.map((prod, index) => {
                return (
                  <CartItem
                    key={`product_${index}`}
                    product={prod}
                    functions={functions}
                    index={index}
                    currency={locale.currency}
                  />
                );
              })
            ) : (
              <div style={{ padding: "2.4rem" }}>Cart Empty</div>
            )}
          </div>
          <div style={{ marginRight: "2.4rem" }}>
            Total:
            {cart.products.length
              ? cartTotal(cart.products, locale.currency)
              : ` 0.00`}
            <br></br>
            <p style={{ color: "grey", fontSize: "1rem;" }}>
              Shipping:
              {(() => {
                switch (locale.country) {
                  case "nl":
                    return " €5.00";
                  case "gbp":
                    return " £20.00";
                  case "eu":
                    if (locale.currency === "gbp") {
                      return " £20.00";
                    } else if (locale.currency === "eur") {
                      return "€ 15.00";
                    } else {
                      return " 0.00";
                    }
                  default:
                    return " 0.00";
                }
              })()}
            </p>
          </div>
        </CartOverflow>
        <CartFooter>
          {/* TEMP */}
          {/* <CheckoutPaypalButton cart={cart} disabled={!terms} /> */}
          <CheckoutButton cart={cart} disabled={!terms} locale={locale} />
          <LegalText>
            <input
              onChange={() => acceptTerms(!terms)}
              type="checkbox"
              id="terms-conditions"
            />
            <label htmlFor="terms-conditions">
              I have read and accept the{" "}
              <Link to="/terms-and-conditions" target="_blank">
                Terms & Conditions
              </Link>{" "}
              and I agree to the{" "}
              <Link to="/privacy" target="_blank">
                Privacy Policy
              </Link>
            </label>
          </LegalText>
        </CartFooter>
      </StyledCart>
      {cartState ? (
        <Backdrop onClick={() => toggleDrawer("cart")} />
      ) : undefined}
    </>
  );
};

export { Cart };
