import styled, { css } from "styled-components";

export const Icon = styled.span`
  cursor: ${props => props.interactive ? 'pointer' : 'default'};
  width: 1.2rem;
  height: 1.2rem;

  ${props => props.interactive && css`
    cursor: pointer;

    &:hover{
      color: #FE7796
    }
  `}
`
