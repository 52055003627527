import React from "react";

import styled from "styled-components";
import { tokens } from "../css/tokens";

export const PageWrapper = styled.div`
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.main`
    order: 0;
    min-width: 100vw;
    scroll-behavior: smooth;
    transition: margin-left 0.3s;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const Section = styled.section`
    width: 100vw;
    max-width: 100vw;
    font-size: 0;
    background-color: #fff;
`;

export const Container = styled.div`
    display: inherit;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
    font-size: 1.4rem;
    padding-bottom: 4rem;
    z-index: 1;

    padding-left: 20px !important;
    padding-right: 20px !important;

    @media screen and (min-width: ${tokens.breakpoints.small}) {
        font-size: 1.6rem;
    }
`;

export const Column6 = styled.div`
    flex: 0 0 auto;
    width: 100%;
    @media screen and (min-width: 992px) {
        width: 50%;
    }
`;

export const Column5 = styled.div`
    flex: 0 0 auto;
    width: 100%;
    @media screen and (min-width: 992px) {
        width: 41.66666667%;
    }
`;

export const Block = styled.div`
    flex: 1 1 29%;
    background-color: #eee;
    padding: ${tokens.spacing.s4};
    h3 {
        border-bottom: 1px solid ${tokens.colors.white};
        padding-bottom: ${tokens.spacing.s4};
    }
`;


const Notification = styled.div`
    font-size: ${tokens.fontSize.smallest};
    display: flex;
    color: ${tokens.colors.gray};
    border: 1px solid currentColor;
    border-radius: 4px;
    padding: 2px 6px;

    &.warning {
        color: orange;
    }
    &.danger {
        color: red;
    }

    svg {
        margin: 3px 4px 3px 0;
        width: ${tokens.fontSize.smallest};
        height: ${tokens.fontSize.smallest};
        min-width: ${tokens.fontSize.smallest};
        min-height: ${tokens.fontSize.smallest};
    }
`;

export const Row = styled.div`
    display: flex;
    gap: ${tokens.spacing.s5};
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;

    @media screen and (min-width: ${tokens.breakpoints.small}) {
        gap: ${tokens.spacing.s6};
        flex-direction: row;
        margin: ${tokens.spacing.s6} 0;
    }
`;

export const Notes = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1rem;
`;

export const Note = ({ note, ...props }) => {
    let svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
        </svg>
    );

    switch (note.type) {
        case "warning":
            svg = (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                </svg>
            );
            break;
        case "information":
            svg = (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
                </svg>
            );
            break;
        case "danger":
            svg = (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z" />
                </svg>
            );
            break;
        default:
            svg = (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
                </svg>
            );
    }

    return (
        <Notification {...props} className={note.type}>
            {svg}
            {note.message}
        </Notification>
    );
};
