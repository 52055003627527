import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { tokens } from "../css/tokens";
import { withRouter } from "react-router";

import { ProductQuantity } from "./Form";
import { Modal, Backdrop } from "./Modal";
import { Price } from "./Price";
import { OrderButton } from "./ProductFunctions";
import { ImageSlider } from "./Slider";
import { StockMessage } from "./StockMessage";

const StyledColorSwatches = styled.div`
  span {
    color: ${tokens.colors.gray};
  }
`;

const ColorDot = styled.input`
  box-shadow: 0 0 2px #000;
  background-color: ${(props) => props.color.split("/")[0]};
  border-color: ${(props) =>
    !!props.color?.split("/")[1]
      ? props.color.split("/")[1]
      : props.color.split("/")[0]};
  border-width: 2px;
  border-style: solid;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  display: inline-block;
  appearance: none;
  // cursor: pointer;

  // :checked{
  //   outline: 2px solid #000;
  //   outline-offset: 1px;
  // }

  // ${(props) =>
    props.color.split("/")[0] === "White" &&
    css`
      //   border-color: #ddd;
      //
    `}
`;

const ColorSelect = styled.select`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='${(
    props
  ) =>
    props.color.split(
      "/"
    )[0]}' stroke-width='10' stroke='black' d='M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z'/%3E%3C/svg%3E");
  background-size: 1.6rem;
  background-repeat: no-repeat;
  background-position: 1.2rem center;
  padding: 1.2rem 1.6rem 1.2rem 3.2rem;
  border: 1px solid #ddd;
  border-radius: var(--radius);
`;

const Select = styled.select`
  padding: 1.2rem;
  border: 1px solid #ddd;
  border-radius: var(--radius);
  margin-bottom: 1.6rem;
`;

const OptionSelect = ({
  option,
  childToParent,
  required,
  placeholder,
  ...props
}) => {
  const [checked, setChecked] = useState(true);

  let selectedOption = useRef();

  const setOption = (selected, evt) => {
    setChecked(selected);
    childToParent(evt.target.value, evt.target);
  };

  return (
    <Select
      {...props}
      ref={selectedOption}
      onChange={(e) => {
        setOption(!checked, e);
      }}
      required={required}
      defaultValue={"default"}
      style={{ width: "100%" }}
    >
      <option disabled value={"default"}>
        {!!placeholder ? placeholder : "Choose your option"}
      </option>
      {!!option
        ? option.map((value, index) => {
            return (
              <option name="colour" key={index} value={value}>
                {value}
              </option>
            );
          })
        : undefined}
    </Select>
  );
};

const ColorSwatches = ({
  colors,
  title,
  limit,
  childToParent,
  type,
  ...props
}) => {
  const [checked, setChecked] = useState(true);

  const selectedColor = useRef([]);

  const setColor = (selected, evt) => {
    setChecked(selected);
    childToParent(evt.target.value);
  };

  return (
    <StyledColorSwatches style={{ padding: 0 }} {...props}>
      {type === "select" ? (
        title ? (
          <strong>Colours</strong>
        ) : (
          ""
        )
      ) : title ? (
        <h3>Colours</h3>
      ) : (
        ""
      )}
      <div
        style={{
          gap: "5px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {type === "select" ? (
          <ColorSelect
            required
            color={
              !!selectedColor.current.value
                ? selectedColor.current.value
                : "%23ccc"
            }
            defaultValue={"default"}
            ref={selectedColor}
            onChange={(e) => {
              setColor(!checked, e);
            }}
            style={{ width: "100%" }}
          >
            <option disabled value={"default"}>
              Choose your colour
            </option>
            <option disabled>Primary/Secondary</option>
            {colors.map((color, index) => {
              return (
                <option name="colour" key={index} value={color}>
                  {color}
                </option>
              );
            })}
          </ColorSelect>
        ) : (
          <>
            {colors.map((color, index) => {
              if (limit) {
                if (index < 5) {
                  return (
                    <label
                      style={{ fontSize: 0, margin: 0 }}
                      key={index}
                      htmlFor={`colour${index}`}
                    >
                      <ColorDot
                        ref={(el) => (selectedColor.current[index] = el)}
                        type="radio"
                        id={`colour${index}`}
                        name="colour"
                        color={color}
                        value={color}
                        title={color}
                      />
                    </label>
                  ); //onChange={(e) => { setColor(!checked, e) }}
                } else {
                  return false;
                }
              } else {
                return (
                  <label
                    style={{ fontSize: 0, margin: 0 }}
                    key={index}
                    htmlFor={`colour${index}`}
                  >
                    <ColorDot
                      ref={(el) => (selectedColor.current[index] = el)}
                      type="radio"
                      id={`colour${index}`}
                      name="colour"
                      color={color}
                      value={color}
                      title={color}
                    />
                  </label>
                ); //onChange={(e) => { setColor(!checked, e) }}
              }
            })}
          </>
        )}
        {limit ? (
          <span>{colors.length >= 5 ? `+${colors.length - 5}` : ""}</span>
        ) : (
          ""
        )}
      </div>
    </StyledColorSwatches>
  );
};

const StyledQuantitySelect = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  flex: 1;
  padding: 20px;
`;

const QuantitySelect = ({ ...props }) => {
  return (
    <StyledQuantitySelect>
      Cart Interactions
      <ProductQuantity />
    </StyledQuantitySelect>
  );
};

export const ProductWrapper = styled.div`
  padding: 10px;
  width: 100%;

  @media screen and (min-width: 576px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) {
    width: 33%;
  }
`;

export const StyledProduct = styled.a`
    background-color: #fff;
    cursor: pointer;
    display: flex;
    gap: 16px;
    height: 100%;
    overflow: hidden;
    color: #303030;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px 16px;
    box-shadow: 0px 0px 16px 0px rgba(21, 37, 76, 0.10);
    :hover {
        filter: brightness(1) !important;
    }
    .product-header{
      width: 100%;
      display: block;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 768px){
        display: flex;
      }
    }

    h2{
      font-size: 2.4rem;
      color: #22DC20;
      @media screen and (min-width: 768px){
        margin-bottom: 1.8rem;
      }
    }

    @media screen and (min-width: 768px){
      flex-direction: column;
    }

    small {
        font-size: 12px;
        padding-bottom: 4px;
    }
  }
`;

const StyledSkeletonProduct = styled(StyledProduct)`
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  &:hover {
    box-shadow: none;
    cursor: wait;
  }
  .product__image,
  h2,
  .product__description > div,
  small,
  .product__id,
  button span,
  button,
  .product__stock,
  .price__main {
    position: relative;
    overflow: hidden;
    border-radius: 6px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      animation: shimmer 2s infinite;
    }
  }
  .product__image,
  small,
  .product__id,
  button span,
  .product__stock {
    background-color: #eee;
    margin-bottom: 8px;
    display: inline-block;
  }
  small,
  .product__id,
  .product__stock {
    width: 100px;
  }
  .price__main {
    width: 120px;
  }
  h2,
  .product__description > div,
  button,
  .price__main {
    background-color: #ccc !important;
  }
  button {
    pointer-events: none;
    span {
      background-color: #fff;
      margin-bottom: 0;
    }
  }
  .product__image {
    padding-top: 18.125%;
  }
  h2 {
    width: 200px;
  }
  .product__id {
    white-space: nowrap;
  }
  .product__description {
    flex: 2 1 0%;
    padding-right: 1.6rem;
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 1rem;
      overflow: hidden;

      &:last-child {
        max-width: 60%;
      }
    }
  }
  .product__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 1.8rem;
    font-weight: 700;
    gap: 0.8rem;
    margin: 2rem 0 1.8rem;
  }
`;

export const RelatedProduct = styled(StyledProduct)`
  padding: 0;
  position: relative;
  flex-direction: row;
  cursor: default;
  width: 100%;
  border: ${(props) => (!!props.border ? "1px solid #ddd" : "none")};

  .related-product__image {
    border-radius: 0;
    position: relative;
    width: auto;

    img {
      max-width: 20rem;
    }
  }

  .related-product__content {
    padding: 1.6rem;
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover {
    box-shadow: none;
  }

  h4 {
    text-align: center;
    padding: 0 0 0.8rem;
    line-height: 1.5;
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

export const ProductImage = styled.div`
  font-size: 0;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
export const ProductContent = styled.div`
  span {
    color: #aaa;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
  }
  h2 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    margin-bottom: 0px;
  }
`;

const ProductActions = styled.div`
  display: block;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  align-items: center;
  justify-content: center;
  overflow: auto;

  > span svg {
    width: 2.4rem;
    cursor: pointer;
    color: ${tokens.colors.pink};
  }
`;

const Product = ({
  data,
  cart,
  functions,
  primary,
  allProducts,
  locale,
  ...props
}) => {
  const relatedProducts = allProducts.filter((product) =>
    data.related.some((related) => related === product.id)
  );

  const [modal, openModal] = useState(false);

  const modalFunction = (toggleState) => {
    if (toggleState === false) {
      document.body.classList.remove("drawer-open");
    } else {
      document.body.classList.add("drawer-open");
    }
    openModal(toggleState);
  };

  return (
    <>
      <ProductWrapper {...props}>
        <StyledProduct
          onClick={(e) => {
            if (
              !e.target.classList.contains("primary") &&
              !e.target.hasAttribute("data-controls")
            ) {
              props.history.push({
                pathname: `/product/${data.name
                  .toLowerCase()
                  .replace(/ /g, "-")}`,
                state: { stateCart: JSON.stringify(cart) },
                relatedProducts: relatedProducts,
              });
            }
          }}
        >
          <div className="product-header labels">
            <StockMessage stock={data.stock.amount} />
          </div>
          <ProductImage>
            <ImageSlider slides={data.images} small />
          </ProductImage>
          <ProductContent>
            <span style={{ color: tokens.colors.gray, whiteSpace: "nowrap" }}>
              #{data.id}
            </span>
            <div className="product-header">
              <h2>{data.name}</h2>
            </div>
            {data.options.printColor.options.length >= 1 ? (
              <ProductActions>
                <Price
                  tax={true}
                  value={data.prices[0]}
                  currency={locale.currency}
                  margin={true}
                />
                <OrderButton
                  className="primary-new"
                  label="Choose option"
                  cart={functions}
                  prod={data}
                  onClick={() => modalFunction(!modal)}
                />
              </ProductActions>
            ) : (
              <ProductActions>
                <Price
                  tax={true}
                  value={data.prices[0]}
                  currency={locale.currency}
                  margin={true}
                />
                <OrderButton
                  className="primary-new"
                  label="Add to cart"
                  cart={functions}
                  prod={data}
                  onClick={() => modalFunction(!modal)}
                />
              </ProductActions>
            )}
          </ProductContent>
        </StyledProduct>
      </ProductWrapper>
      {modal ? (
        <ModalWrapper>
          <Backdrop onClick={() => modalFunction(false)} />
          <Modal
            type="select"
            functions={functions}
            openModal={modalFunction}
            related={relatedProducts}
            product={data}
            modal={modal}
            locale={locale}
          />
        </ModalWrapper>
      ) : (
        ""
      )}
    </>
  );
};

const SkeletonProduct = ({ ...props }) => {
  return (
    <ProductWrapper>
      <StyledSkeletonProduct>
        <ProductImage className="product__image" />
        <ProductContent>
          <span className="product__id">&nbsp;</span>
          <div className="product-header">
            <h2>&nbsp;</h2>
            <span className="product__stock">&nbsp;</span>
          </div>
          <div className="product__description">
            <small>&nbsp;</small>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
          <ProductActions>
            <div className="product__price">
              <span className="price__main">&nbsp;</span>
              <small>&nbsp;</small>
            </div>
            <OrderButton
              className="primary disabled"
              cart={[]}
              prod={[]}
              disabled={true}
            />
          </ProductActions>
        </ProductContent>
      </StyledSkeletonProduct>
    </ProductWrapper>
  );
};

export default withRouter(Product);
export { ColorSwatches, QuantitySelect, OptionSelect, SkeletonProduct };
