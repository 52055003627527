import React from "react";

import { PageWrapper, Wrapper } from "../components/Elements";
import { MainMenu } from "../components/MainMenu";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Cart } from "../components/Cart";
import { ShopProducts } from "../components/pages/ShopProducts";
import { HorizontalScroll } from "../components/HorizontalScroll";
import { Countdown } from "../components/Countdown";

export class Shop extends React.Component {
  componentDidMount() {
    setTimeout(window.scrollTo(0, 0), 0);
  }

  render() {
    return (
      <PageWrapper>
        <HorizontalScroll color="#FE7796" />
        <Countdown
          endtime={1682891999}
          discount={"15%"}
          voucher="JUICY15"
          title
          days
          hours
          minutes
          seconds
          style={{ position: "relative" }}
        />
        <MainMenu
          toggleDrawer={this.props.toggleDrawer}
          menuState={this.props.menuState}
          leftSide={true}
        />

        <Wrapper className="shop">
          <ShopProducts
            locale={this.props.locale}
            cart={this.props.cart}
            products={this.props.products}
            functions={this.props.functions}
          />
        </Wrapper>
        <Footer showLabels={true} rating={this.props.rating}  />
        <Header className="back" />
        <Cart
          toggleDrawer={this.props.toggleDrawer}
          cartState={this.props.cartState}
          cart={this.props.cart}
          functions={this.props.functions}
          locale={this.props.locale}
        />
      </PageWrapper>
    );
  }
}

export default Shop;
