import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { tokens } from "../css/tokens";

const StyledCountdown = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${tokens.colors.pink};
  color: #fff;
  text-align: center;
  padding: 1.2rem 1rem 1rem;
  z-index: 4;
  font-size: 1.6rem;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    appearance: none;
    cursor: pointer;
    background: transparent;
    border: none;
    color: inherit;
    font-weight: bold;
  }

  span:not(:last-of-type)::after {
    content: ":";
  }

  > .time-wrapper {
    display: inline-flex;
    gap: 2px;
    font-size: 1.6rem;

    span.time-partial {
      color: #fff;
      background-color: currentColor;
      padding: 0.8rem;
      position: relative;
      line-height: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 5rem;

      &:not(:first-of-type) {
        width: 7rem;
        padding-left: 1.8rem;
      }

      small {
        color: ${tokens.colors.pink};
        z-index: 2;
        position: relative;
        font-size: 1rem;
        order: 1;
      }
      span {
        color: #333;
      }

      &::before {
        content: "";
        position: absolute;
        right: 100%;
        border-color: transparent currentColor transparent transparent;
        border-style: solid;
        width: 0;
        height: 0;
        border-width: 46px 20px 0;
        top: 0;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        left: 100%;
        border-color: transparent transparent transparent currentColor;
        border-style: solid;
        width: 0;
        height: 0;
        border-width: 0px 0 46px 20px;
        top: 0;
        z-index: 1;
      }
    }
  }
`;
const Countdown = ({
  endtime,
  discount,
  voucher,
  days,
  hours,
  minutes,
  seconds,
  title,
  ...props
}) => {
  //const startTime = Date.now();
  const [timeLeft, setTimeLeft] = useState(endtime - Date.now() / 1000);

  let daysLeft = Math.floor(timeLeft / (60 * 60 * 24));
  let hoursLeft = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
  let minutesLeft = Math.floor((timeLeft % (60 * 60)) / 60);
  let secondsLeft = Math.floor(timeLeft % 60);

  const copyToClipboard = (elm) => {
    elm = elm.target;
    elm.select();
    elm.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(elm.value);
  };

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      if (hoursLeft === 0 && minutesLeft === 0 && secondsLeft === 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        daysLeft = !!daysLeft ? daysLeft - 1 : 0;
      } else if (minutesLeft === 0 && secondsLeft === 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        hoursLeft = !!hoursLeft ? hoursLeft - 1 : 0;
      } else if (secondsLeft === 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        minutesLeft = !!minutesLeft ? minutesLeft - 1 : 0;
        setTimeLeft(endtime - Date.now() / 1000);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  if (timeLeft > 0) {
    return (
      <StyledCountdown {...props}>
        <div>
          Get <strong>{discount} discount</strong> with promo code{" "}
          <input
            readOnly
            size={voucher.length}
            onClick={copyToClipboard}
            value={voucher}
          />
          on checkout!
        </div>
        <div className={title ? "time-wrapper" : undefined}>
          {days ? (
            <span className="time-partial" style={{ color: "#ffe0e8" }}>
              {title ? <small>Days</small> : undefined}
              <span>{("0" + daysLeft).slice(-2)}</span>
            </span>
          ) : undefined}
          {hours ? (
            <span className="time-partial" style={{ color: "#ffebf0" }}>
              {title ? <small>Hours</small> : undefined}
              <span>{("0" + hoursLeft).slice(-2)}</span>
            </span>
          ) : undefined}
          {minutes ? (
            <span className="time-partial" style={{ color: "#fff5f7" }}>
              {title ? <small>Minutes</small> : undefined}
              <span>{("0" + minutesLeft).slice(-2)}</span>
            </span>
          ) : undefined}
          {seconds ? (
            <span className="time-partial" style={{ color: "#ffe6ec" }}>
              {title ? <small>Seconds</small> : undefined}
              <span>{("0" + secondsLeft).slice(-2)}</span>
            </span>
          ) : undefined}
        </div>
      </StyledCountdown>
    );
  } else {
    return false;
  }
};

export { Countdown };
