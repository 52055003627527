import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const ScrollBar = styled.div`
  height: 4px;
  width: 0;
  background-color: ${(props) => props.color};
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
`

const HorizontalScroll = ({ color, ...props }) => {
  const horScroll = useRef();

  useEffect(() => {
    const handleScroll = event => {
      let progress = window.pageYOffset / (document.body.offsetHeight - window.innerHeight) * 100;
      horScroll.current.style.width = `${progress}%`;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <ScrollBar ref={horScroll} color={color}/>
  );
};

export { HorizontalScroll };
