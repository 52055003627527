import React, { useRef } from "react";
import styled from "styled-components";
import { tokens } from "../css/tokens";

export const InputWrapper = styled.div`
    position: relative;
    padding: ${(props) => (props.padding ? "0 2rem" : "0")};
    border-left: ${(props) => (props.border ? "1px solid #ddd" : "none")};
`;

export const Input = styled.input`
    width: 5rem;
    text-align: center;
    font-weight: bold;
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: var(--radius);
    margin-right: 1rem;
    background-color: #fff;
`;

export const StyledSearchField = styled(Input)`
    border: 1px solid rgb(102, 102, 102);
    text-align: left;
    font-weight: normal;
    padding: ${(props) => (props.close ? "0 28px" : "0 8px 0 28px")};
    background-color: transparent;
    height: 28px;
    color: #fff;
    flex: 1;
    max-width: 30rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23bbb' d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 8px center;
    background-size: 14px;
    width: 100%;

    &::placeholder {
        color: #bbb !important;
    }

    &:focus {
        border-color: #ddd;
        box-shadow: none !important;
    }
`;

const StyledButton = styled.button`
    color: ${(props) => tokens.colors[props.color || "pink"]};
    background-color: transparent;
    border: 1px solid currentColor;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    margin: 0;
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    justify-content: center;
    font-size: 1.4rem;

    svg {
        width: 2rem;
        height: 2rem;
        pointer-events: none;
    }

    span {
        pointer-events: none;
        display: none;
    }

    @media screen and (min-width: ${tokens.breakpoints.medium}) {
        span {
            display: inline;
        }
        svg {
            display: block;
            margin-right: 0.8rem;
        }
    }

    &:hover {
        background-color: ${(props) => tokens.colors[props.color || "pink"]};
        color: #fff;
        border-color: ${(props) => tokens.colors[props.color || "pink"]};
        transform: scale(1.1, 1.1);
    }

    &.primary {
        background-color: ${tokens.colors.pink};
        color: #fff;
    }
    &.primary-new {
        height: fit-content !important;
        display: flex;
        padding: 26px 56px;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 8px;
        border-radius: 4px;
        background: #016500;
        border: none;
        span {
            color: #fff;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 700;
            display: block !important;
        }
        svg {
            height: 16px !important;
            > * {
                fill: #fff !important;
            }
        }
        &:hover {
            transform: none;
            background-color: #015100;
        }
        &:focus {
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.47) inset;
        }
    }

    &.secondary {
        background-color: ${tokens.colors.green};
        color: #fff;

        &:hover {
            background-color: ${tokens.colors.green};
            color: #fff;
            border-color: ${tokens.colors.green};
        }
    }

    &:focus {
        z-index: auto !important;
    }
`;

const ClearBtn = styled.div`
    width: 2.8rem;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.8rem;
`;

export const Dropdown = styled.div`
    background: red;
    position: absolute;
    width: 5rem;
`;

export const DropdownItem = styled.div`
    background: red;
    height: 4rem;
    text-align: center;
    line-height: 4rem;
`;

export const Button = ({ type, fullWidth, color, ...props }) => {
    return (
        <StyledButton color={color} {...props} fullWidth={fullWidth} className={[type, props.className]}>
            {props.children}
        </StyledButton>
    );
};

const ProductQuantity = ({ quantity, title, childToParent, ...props }) => {
    const qtyInput = useRef();

    return (
        <>
            {/*<Button onClick={() => qtyInput.current.stepUp(1)} />*/}
            <Input min="1" ref={qtyInput} type="number" defaultValue={1} onChange={(e) => childToParent(qtyInput.current.value)} />
            {/*<Button onClick={() => qtyInput.current.stepDown(1)} />*/}
        </>
    );
};

const SearchField = ({ query, updateQuery, ...props }) => {
    return (
        <InputWrapper style={{ width: "100%", maxWidth: "30rem" }}>
            <StyledSearchField
                {...props}
                close={query.length}
                ref={props.forwardRef}
                value={query}
                onChange={(e) => {
                    updateQuery(e.target.value);
                }}
            />
            {query.length ? (
                <ClearBtn onClick={() => updateQuery("")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                </ClearBtn>
            ) : undefined}
        </InputWrapper>
    );
};

export { ProductQuantity, SearchField };
