/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { tokens } from "../css/tokens";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Backdrop } from "./Modal";

const MenuWrapper = styled.nav`
  order: 1;
  background-color: #303030;
  position: fixed;
  color: #fff;
  height: 100%;
  height: 100vh;
  min-width: 100vw;
  font-family: "Saira Condensed", "Saira", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
  padding: ${tokens.spacing.s6} ${tokens.spacing.s6} ${tokens.spacing.s8};
  z-index: 15;
  text-align: right;
  bottom: 0;
  ${(props) =>
    props.leftSide
      ? css`
          left: -100vw;
          transition: left 0.3s, margin 0.3s;
          justify-content: flex-start;
        `
      : css`
          right: -100vw;
          transition: right 0.3s, margin 0.3s;
          justify-content: flex-end;
        `}

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 2.4rem;
  }

  li a {
    color: #b8b8b8;
    text-decoration: none;
    border-right: 1px solid #4e4e4e;
    padding: ${tokens.spacing.s2} ${tokens.spacing.s4} ${tokens.spacing.s2} 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  li a svg {
    height: 2rem;
    margin-left: 0.8rem;
  }

  li a:hover,
  li.active a {
    border-right: 1px solid currentColor;
  }

  .menu_toggle {
    position: fixed;
    bottom: 10rem;
    height: 6rem;
    ${(props) =>
    props.leftSide
      ? css`
            left: -6rem;
            transition: left 0.3s;
            flex-direction: row-reverse;
          `
      : css`
            right: -6rem;
            transition: right 0.3s;
          `}
    display: flex;
    z-index: 10;
    cursor: pointer;
    border: 1px solid #d4d4d4;
  }
  .menu_toggle span {
    width: 6rem;
    height: 100%;
    background-color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_toggle span svg {
    width: 2rem;
  }
  .menu_toggle span.toggle--close svg {
    width: 1.6rem;
    ${(props) =>
    props.leftSide
      ? css`
            margin-right: -1px;
          `
      : css`
            margin-left: -1px;
          `}
  }

  @media screen and (min-width: ${tokens.breakpoints.medium}) {
    .menu_toggle {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (hover: hover) {
    &:hover {
      ${(props) =>
    props.leftSide
      ? css`
              margin-left: 4rem;
            `
      : css`
              margin-right: 4rem;
            `}

      .menu_toggle {
        ${(props) =>
    props.leftSide
      ? css`
                left: -2rem;
              `
      : css`
                right: -2rem;
              `}
      }
    }
  }

  @media screen and (min-width: ${tokens.breakpoints.xsmall}) {
    min-width: var(--drawerDesktop);
    align-items: center;
    ${(props) =>
    props.leftSide
      ? css`
            left: calc(var(--drawerDesktop) * -1);
          `
      : css`
            right: calc(var(--drawerDesktop) * -1);
          `};
    padding: ${tokens.spacing.s6};
  }

  &::before {
    content: "";
    position: absolute;
    ${(props) =>
    props.leftSide
      ? css`
            left: 100%;
          `
      : css`
            right: 100%;
          `}
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: #000;
    z-index: 5;
    transition: opacity 0.3s;
    pointer-events: none;
  }
  &.open {
    z-index: 20;
    ${(props) =>
    props.leftSide
      ? css`
            left: 0;
            margin-left: 0;
          `
      : css`
            right: 0;
            margin-right: 0;
          `}

    .menu_toggle {
      ${(props) =>
    props.leftSide
      ? css`
              left: calc(100vw - 6rem);
            `
      : css`
              right: calc(100vw - 6rem);
            `}

      @media screen and (min-width: ${tokens.breakpoints.xsmall}) {
        ${(props) =>
    props.leftSide
      ? css`
                left: calc(var(--drawerDesktop) - 6rem);
              `
      : css`
                right: calc(var(--drawerDesktop) - 6rem);
              `}
      }
    }
  }
`;

const MainMenu = ({ leftSide, toggleDrawer, menuState, dynamic }) => {
  const sections = document.querySelectorAll("section");
  const navLi = document.querySelectorAll("nav.site-menu li");

  const location = useLocation();

  const scrollToSection = (event) => {
    navLi.forEach((li) => {
      li.classList.remove("active");
    });
    event.target.parentNode.classList.add("active");
    toggleDrawer();
  };

  if (dynamic) {
    const setActiveSection = () => {
      var current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;

        if (window.pageYOffset >= sectionTop) {
          current = section.getAttribute("id");
        }
      });

      navLi.forEach((li) => {
        li.classList.remove("active");
        if (li.classList.contains(current)) {
          li.classList.add("active");
        }
      });
    };

    useEffect(() => {
      setActiveSection();

      const handleScroll = (event) => {
        setActiveSection();
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [sections]);
  }

  return (
    <>
      <MenuWrapper
        leftSide={leftSide}
        className={[`site-menu`, menuState ? "open" : undefined]}
      >
        <ul>
          <li
            className={[
              location.pathname === "/home" ? "active" : undefined,
              "top",
            ].join(" ")}
          >
            <HashLink
              to="/home#top"
              className="link"
              onClick={(e) => scrollToSection(e)}
            >
              Home
            </HashLink>
          </li>
          <li
            className={
              location.pathname === "/shop" || location.pathname === "/" ? "active" : undefined
            }
          >
            <Link
              to="/shop"
              onClick={() => toggleDrawer()}
              style={{ cursor: "pointer" }}
              className="link--external"
            >
              Products
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
                />
              </svg>
            </Link>
          </li>
          <li className="about">
            <HashLink
              to="/home#about"
              className="link"
              onClick={(e) => scrollToSection(e)}
            >
              About
            </HashLink>
          </li>
          <li className="contact">
            <HashLink
              to="/home#contact"
              className="link"
              onClick={(e) => scrollToSection(e)}
            >
              Contact
            </HashLink>
          </li>
          <li
            className={
              location.pathname === "/instructions" ? "active" : undefined
            }
          >
            <Link
              to="/instructions"
              onClick={() => toggleDrawer()}
              style={{ cursor: "pointer" }}
              className="link"
            >
              Instructions
            </Link>
          </li>
        </ul>
        <span
          className="menu_toggle"
          id="toggle"
          onClick={() => toggleDrawer("menu")}
        >
          <span className="toggle--open">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="bars"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="svg-inline--fa fa-bars fa-w-14"
            >
              <path
                fill="currentColor"
                d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
                className=""
              ></path>
            </svg>
          </span>
          <span className="toggle--close">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="times"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              className="svg-inline--fa fa-times fa-w-10"
            >
              <path
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                className=""
              ></path>
            </svg>
          </span>
        </span>
      </MenuWrapper>
      {menuState ? (
        <Backdrop onClick={() => toggleDrawer("menu")} />
      ) : undefined}
    </>
  );
};

export { MainMenu };
