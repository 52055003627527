import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container } from "./Elements";
import melonsImg from "../assets/jm_kiki.jpg";
import { Button } from "./Form";

const BannerContainer = styled.div`
  border-radius: 24px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.46) 0%,
      rgba(0, 0, 0, 0.46) 100%
    ),
    url(${melonsImg});
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 88px 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  h2 {
    color: #fff;
    font-family: Montserrat;
    font-size: 31px;
    font-weight: 800;
    line-height: 110%;
  }
  p {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
  }
  button {
    width: fit-content;
  }
  @media screen and (max-width: 992px) {
    padding: 64px 24px;
  }
`;

export const Banner = ({ title, txt, buttonTxt }) => {
  let history = useHistory();

  return (
    <Container>
      <BannerContainer>
        <h2>{title}</h2>
        <p>{txt}</p>
        <Button className={"primary-new"} onClick={() => history.push("/shop")}>
          <span>{buttonTxt}</span>
        </Button>
      </BannerContainer>
    </Container>
  );
};
