import React from "react";
import logo from "../../assets/jm_logo-banner.png";

import { Section, Container, Row, Block } from "../Elements";

const Contact = () => {
    return (
        <Section id="contact">
          <Container style={{ padding: "4rem 0" }}>
            <h2>Contact</h2>
            <p>
              Feel free to contact us! Send us a message with your question or issue and we'll do our best to answer you as soon as possible!
            </p>
            <Row>
              <Block>
                <h3 className={"border-bottom"}>Address</h3>
                <p>
                  <strong>Juicy Melons</strong><br/>
                  Van den Boetzelaartstraat 42<br/>
                  6081 BT, Haelen<br/>
                  The Netherlands
                </p>
              </Block>
              <Block>
                <h3 className={"border-bottom"}>Email address</h3>
                <p>
                  <a href="mailto:juicymelons.shop@gmail.com">juicymelons.shop@gmail.com</a>
                  <br/><br/>
                  Or send us a message via one of our social media channels.
                </p>
              </Block>
              <Block style={{ background: 'transparent', alignItems: 'center', justifyContent: 'center', display: 'flex', padding: 0, margin: 0 }}>
                <img src={logo} style={{ maxWidth: '25rem' }} alt="Juicy Melons Surron Parts Logo" />
              </Block>
            </Row>
          </Container>
        </Section>
    );
};

export { Contact };
