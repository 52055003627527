import React from "react";

import styled from "styled-components";

export const StyledStockMessage = styled.div`
    color: #fff;
    padding: 4px 8px;
    background-color: ${(props) => props.color};
    border-radius: 2px;
    display: inline-block;
    margin: 1rem 0 1.8rem;
    font-size: 12px;
    font-weight: 700;

    @media screen and (min-width: 768px) {
        margin: 0;
    }
`;

export const StockMessage = ({ stock, ...props }) => {
    let stockMessage = "In stock";
    let color = "green";

    if (!stock) {
        stockMessage = "Out of stock";
        color = "lightgrey";
    } else if (stock <= 3) {
        stockMessage = "Almost sold out";
        color = "red";
    } else if (stock <= 5) {
        stockMessage = "Low stock";
        color = "orange";
    }

    return (
        <StyledStockMessage color={color} {...props} style={props.style}>
            {stockMessage}
        </StyledStockMessage>
    );
};
