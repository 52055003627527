import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import melonsImg from "../../assets/jm_kiki.jpg";
import logo from "../../assets/jm_logo-banner.png";
import styled from "styled-components";
import { tokens } from "../../css/tokens.jsx";

import { Section, Container } from "../Elements";
import { Hero } from "./ShopProducts";
import { Footer } from "../Footer";
import { MainMenu } from "../MainMenu";
import { Link } from "react-router-dom";

const InstructionEntry = styled.div`
  .instruction__title{
    margin-bottom: 1rem;

    span{
      cursor: pointer;
    }
  }

  .instruction__content {
    display: flex;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    gap: 1rem;
    transition: height .3s;

    div{
      position: relative;
      text-transform: capitalize;
      max-height: 100%;
    }

    .instruction__readmore, .instruction__readless{
      display: none;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      height: 150px;

      div{
        max-height: none;
      }

      .instruction__readmore, .instruction__readless{
        display: block;
      }
    }
  }

  .instruction__readmore, .instruction__readless{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${tokens.colors.white};
    width: 100%;
    cursor: pointer;
    color: ${tokens.colors.green};
  }

  .instruction__readmore{
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(transparent, white);
      pointer-events: none;
    }
  }
`

const ProductInstruction = ({ id, data, ...props }) => {
  const stepsTarget = useRef();
  const content = useRef();

  useEffect(() => {
    let readMore = document.createElement('span');
    readMore.classList.add('instruction__readmore');
    readMore.innerHTML = 'Read more';

    fetch(`https://youtube.googleapis.com/youtube/v3/videos?key=AIzaSyAexx3NntMlC-YCfcUQYduEYIXIL4i2e7s&id=${id}&part=snippet`, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        stepsTarget.current.innerText = data.items[0].snippet.description.substring(67).trim().toLowerCase();
        if (stepsTarget.current.offsetHeight > 150) {
          stepsTarget.current.parentElement.insertBefore(readMore, stepsTarget.current);
          readMore.addEventListener('click', () => {
            if (content.current.offsetHeight === 150) {
              content.current.style.height = `${stepsTarget.current.offsetHeight + 31}px`;
              readMore.innerHTML = 'Read less';
              readMore.classList.remove('instruction__readmore');
              readMore.classList.add('instruction__readless');
            } else {
              content.current.style.height = '150px';
              readMore.innerHTML = 'Read more';
              readMore.classList.add('instruction__readmore');
              readMore.classList.remove('instruction__readless');
            }
          })
        }
      }
      );
  }, [id])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (windowWidth <= 375) {
      content.current.style.height = 'auto';
    } else {
      content.current.style.height = '150px';
    }

    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  })

  return (
    <InstructionEntry>
      <div className="instruction__title">
        <Link className="h2" to={`/product/${data.name.toLowerCase().replace(/ /g, "-")}`} >{data.name}</Link>
      </div>
      <div className="instruction__content" ref={content}>
        <div>
          <iframe
            src={`https://www.youtube.com/embed/${id}?modestbranding=1&rel=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div>
          <span ref={stepsTarget}></span>
        </div>
      </div>
    </InstructionEntry>
  )
}

const Instructions = ({ products, toggleDrawer, menuState, cart, ...props }) => {
  let history = useHistory();

  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Section style={{ backgroundColor: '#fff' }}>
      <MainMenu toggleDrawer={toggleDrawer} menuState={menuState} />
      <Hero backgroundPhoto={melonsImg} style={{ backgroundPosition: 'center 70%' }} backdrop={false} />
      <img alt="logo" src={logo} height={100} style={{
        position: 'relative',
        zIndex: 2,
        left: '50%',
        transform: 'translateX(-50%)',
        margin: '2rem 0 0',
        cursor: 'pointer'
      }} onClick={() => history.push("/")} />
      <Container style={{ padding: '3rem 0', backgroundColor: '#fff', position: 'relative', zIndex: 2 }}>
        <h1>Instructions</h1>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: tokens.spacing.s5 }}>

          <div style={{ marginTop: '1em' }}>
            Here you can find the instruction videos and steps for our products.<br />
            Please reach out if you are still experiencing troubles with installing your products.
          </div>

          <hr />

          {products.map((prod, index) => {
            if (!prod.video) {
              return false;
            }
            let videoId = prod.video.split('/');
            videoId = videoId[videoId.length - 1];

            return (
              <React.Fragment key={index}>
                <ProductInstruction id={videoId} data={prod} />
                <hr />
              </React.Fragment>
            )
          })}
        </div>

      </Container>
      <Footer rating={this.props.rating} />
    </Section>
  );
}

export { Instructions };
