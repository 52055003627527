import React from "react";
import styled from "styled-components";

import { PageWrapper, Wrapper } from "../components/Elements";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { MainMenu } from "../components/MainMenu";
import { About } from "../components/pages/About";
import { Contact } from "../components/pages/Contact";
import { Reviews } from "../components/pages/Reviews";
import { HorizontalScroll } from "../components/HorizontalScroll";

import CookieConsent from "react-cookie-consent";

export const ArrowStyling = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  span {
    width: 1.8rem;
    height: 1.8rem;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb07 2s infinite;
    animation: sdb07 2s infinite;
    opacity: 0;
    border-radius: 3px;
  }
  span:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  span:nth-of-type(2) {
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }
  span:nth-of-type(3) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  @-webkit-keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export class Landing extends React.Component {
  componentDidMount() {
    setTimeout(window.scrollTo(0, 0), 0);
  }

  render() {
    return (
      <PageWrapper>
        <HorizontalScroll color="#FE7796" />
        <ArrowStyling
          onClick={() =>
            window.scrollTo({
              top: window.innerHeight,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          <span></span>
          <span></span>
          <span></span>
        </ArrowStyling>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          acceptOnScroll={false}
          acceptOnScrollPercentage={50}
          cookieName="juicyCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            background: "#22DC20",
            color: "#4e503b",
            fontSize: "13px",
          }}
          expires={150}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
            } else {
            }
          }}
        >
          {" "}
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <MainMenu
          dynamic={true}
          toggleDrawer={this.props.toggleDrawer}
          menuState={this.props.menuState}
        />
        <Wrapper className="site">
          <Header />
          <About />
          <Reviews reviews={this.props.reviews} rating={this.props.rating} />
          <Contact />
          <Footer style={{ zIndex: 1 }} rating={this.props.rating} />
        </Wrapper>
      </PageWrapper>
    );
  }
}

export default Landing;
