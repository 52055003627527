import React from "react";
import {Section, Container, Row, Block} from "../Elements";
import {StarRating} from "../StarRating";

const Reviews = ({reviews, rating, ...props}) => {

    return (
        <Section id="reviews">
            <Container style={{padding: "4rem 0"}}>
                <h2>What our customers think</h2>
                <StarRating rating={rating} />
                <Row>
                    {reviews.length
                        ? reviews.map((review, index) => (
                            <Block className={index > 1 ? "hide-on-mobile" : ""} key={index}>
                                <div className={"border-bottom"}>
                                    <div>
                                        <img height={40} src={review.profile_photo_url} alt="profile_photo"
                                             referrerPolicy="no-referrer"/>
                                        <div style={{display: "block", marginLeft: "10px"}}>
                                            <h3>{review.author_name}</h3>
                                            <p>{review.relative_time_description}</p>
                                        </div>
                                    </div>
                                    <StarRating rating={review.rating} />
                                </div>
                                <p>{review.text}</p>
                            </Block>
                        ))
                        : undefined
                    }
                    <Block style={{
                        background: 'transparent',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        padding: 0,
                        margin: 0
                    }}>
                    </Block>
                </Row>
            </Container>
        </Section>
    );
};

export {Reviews};
