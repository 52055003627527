import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";

import { ProductQuantity } from "./Form";
import { Button } from "./Form";

const OrderFunctions = styled.div`
    display: inline-flex;
    width: 100%;
    flex: 1;
`;

export const AddToCart = styled.div`
    align-items: flex-start;
    bottom: 0;
    top: unset;
    margin: 0 -4rem;
    padding: 2rem 2rem 3rem 2rem;
    background-color: #eee;
    display: flex;
    position: sticky;
    height: 10rem;

    ${(props) =>
        props.type === "select" &&
        css`
            background-color: #fff;
            position: static;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            height: auto;
            flex-wrap: wrap;
        `}

    @media screen and (min-width: 768px) {
        align-items: center;
        top: 0;
        bottom: 0;
        width: ${(props) => (props.type === "select" ? "100%" : "calc(100% + 8rem)")};
        margin: ${(props) => (props.type === "select" ? "2rem 0" : "1rem -4rem 4rem")};
        padding: ${(props) => (props.type === "select" ? "2rem 0 0" : "2rem 4rem")};
        position: ${(props) => (props.sticky ? "sticky" : "static")};
        height: auto;
        border-bottom: ${(props) => (props.type === "select" ? "none" : "1px solid #ddd")};
        border-top: 1px solid #ddd;
    }
`;

export const OrderButton = ({ prod, cart, quantity, color, colorList, openModal, btnColor, modalFunction, disabled, options, label, ...props }) => {
    const [qty, setQty] = useState(1);

    const childToParent = (childdata) => {
        setQty(childdata);
    };

    prod.color = !!color ? color : null;
    prod.selectedOptions = {};

    const getOptions = () => {
        if (!!options) {
            Object.keys(options).map((option) => {
                prod.selectedOptions[option] = options[option];
                return false;
            });
        }
    };
    getOptions();

    colorList = !!colorList ? colorList : [];

    return (
        <OrderFunctions className="order-button" style={props.style}>
            {quantity ? <ProductQuantity childToParent={childToParent} quantity={10} padding border title /> : undefined}
            <Button
                style={{ flexWrap: "wrap", height: "4.5rem", overflow: "hidden" }}
                fullWidth={true}
                disabled={disabled}
                color={btnColor}
                {...props}
                onClick={(e) => {
                    let locale = JSON.parse(localStorage.getItem("locale"));
                    let currency = locale.currency;

                    let price;
                    switch (currency) {
                        case "eu":
                            price = prod.prices[0].unit_amount_eur;
                            break;
                        case "usd":
                            price = prod.prices[0].unit_amount_usd;
                            break;
                        case "gbp":
                            price = prod.prices[0].unit_amount_gbp;
                            break;
                        default:
                            price = prod.prices[0].unit_amount_eur;
                    }

                    const formatPrice = (price, quantity) => {
                        const priceTotal = price * quantity;
                        const amount = priceTotal.toString();
                        const formattedAmount = amount.slice(0, -2) + "." + amount.slice(-2);
                        return formattedAmount;
                    };

                    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                    window.dataLayer.push({
                        event: "add_to_cart",
                        ecommerce: {
                            currency: currency,
                            value: formatPrice(price, qty),
                            items: [
                                {
                                    item_id: prod.id,
                                    item_name: prod.name,
                                    price: formatPrice(price, 1),
                                    quantity: qty,
                                },
                            ],
                        },
                    });
                    cart.add(prod, qty, e);
                    localStorage.setItem("cart", cart);
                    if (!!modalFunction) {
                        modalFunction(true);
                    }
                    if (!!openModal) {
                        openModal(false);
                    }
                }}
                {...props}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z" />

                </svg>
                <span>{label}</span>
            </Button>
        </OrderFunctions>
    );
};

export const CheckoutPaypalButton = ({ cart, ...props }) => {
    const checkoutCartPaypal = useCallback(async () => {
        let region = localStorage.getItem("region");

        //TODO: separate
        let valuta;
        switch (region) {
            case "eu":
                valuta = "EUR";
                break;
            case "not-eu":
                valuta = "USD";
                break;
            case "nl":
                valuta = "EUR";
                break;
            default:
                valuta = "EUR";
        }
        cart.products.forEach((product) => {
            if (product.price_data.currency === "usd") {
                product.price_data.unit_amount = product.price_data.unit_amount.unit_amount_usd;
            } else {
                product.price_data.unit_amount = product.price_data.unit_amount.unit_amount;
            }
        });
        // Send the payment data to the server
        try {
            const response = await fetch(`/api/create-payment-paypal?region=${region}`, {
                method: "POST",
                body: JSON.stringify({
                    amount: {
                        total: cart.products.map((x) => (x.price_data.unit_amount * x.quantity) / 100).reduce((partialSum, a) => partialSum + a, 0),
                        currency: valuta,
                    },
                    description: cart.products.map((x) => x.price_data.product_data.name + " x" + x.quantity).join("\n"),
                }),

                headers: { "Content-Type": "application/json" },
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const approveLink = await response.json();
            window.location.href = approveLink;
        } catch (error) {
            console.error(error);
        }
    }, [cart]);

    return (
        <div>
            {/* Render a button to initiate the payment */}
            <Button
                fullWidth={true}
                {...props}
                onClick={() => {
                    if (cart.products.length) {
                        checkoutCartPaypal();
                    }
                }}
            >
                Pay with PayPal
            </Button>
        </div>
    );
};

export const CheckoutButton = ({ cart, locale, ...props }) => {
    function checkoutCart() {
        const updatedProducts = cart.products.map((product) => {
            product.price_data.unit_amount = product.price_data.unit_amount[`unit_amount_${locale.currency}`];
            return product;
        });

        let value = 0;
        let items = [];

        const formatPrice = (price) => {
            const amount = price.toString();
            const formattedAmount = amount.slice(0, -2) + "." + amount.slice(-2);
            return formattedAmount;
        };

        updatedProducts.forEach((item) => {
            value += formatPrice(item.price_data.unit_amount) * item.quantity;
            items.push({
                item_name: item.price_data.product_data.name,
                price: formatPrice(item.price_data.unit_amount),
                quantity: item.quantity,
            });
        });

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: locale.currency,
                value: value,
                items: items,
            },
        });

        return fetch(
            "/api/create-checkout-session?" +
                new URLSearchParams({
                    region: locale.country,
                    currency: locale.currency,
                }),
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    line_items: updatedProducts,
                }),
            }
        )
            .then((res) => {
                if (res.ok) return res.json();
                // If there is an error then make sure we catch that
                return res.json().then((e) => Promise.reject(e));
            })
            .then(({ url }) => {
                // On success redirect the customer to the returned URL
                window.location = url;
            })

            .catch((e) => {
                console.error(e.error);
            });
    }

    return (
        <>
            <Button
                fullWidth={true}
                {...props}
                onClick={() => {
                    if (cart.products.length) {
                        checkoutCart();
                    }
                }}
            >
                Checkout
            </Button>
        </>
    );
};
