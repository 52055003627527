import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { withRouter } from "react-router";

import Landing from "./views/Landing";
import Shop from "./views/Shop";
import NotFound from "./views/Landing";
import ProductDetail from "./views/ProductDetail";
import { Success } from "./components/pages/Success";
import { Cancel } from "./components/pages/Cancel";
import { Terms } from "./components/pages/Terms";
import { Privacy } from "./components/pages/Privacy";
import { Instructions } from "./components/pages/Instructions";

// GOOGLE TAG MANAGER
import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: "GTM-NR7FT5W5",
};

TagManager.initialize(tagManagerArgs);

export class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locale: !!localStorage.getItem("locale")
                ? JSON.parse(localStorage.getItem("locale"))
                : {
                      country: null,
                      currency: "eur",
                  },
            products: [],
            reviews: [],
            rating: '',
            cart: !!localStorage.getItem("cart")
                ? JSON.parse(localStorage.getItem("cart"))
                : {
                      products: [],
                      total: 0,
                      description: null,
                      voucher: null,
                  },
            functions: {
                setCountry: (locale) => {
                    this.setState({ locale: locale });
                },
                remove: (index) => {
                    this.state.cart.products.splice(index, 1);
                    this.setState({ cart: this.state.cart });
                    this.setLocalCart(this.state.cart);
                },
                clear: () => {
                    this.setState({ cart: { products: [] } });
                    this.setLocalCart(this.state.cart);
                },
                update: (index, qty) => {
                    qty = Number(qty);
                    let cloneArray = [...this.state.cart.products];
                    cloneArray[index] = { ...(cloneArray[index].qty = qty) };
                    this.setState({ cloneArray });
                    this.setLocalCart(this.state.cart);
                },
                add: (row, qty, evt) => {
                    evt.preventDefault();
                    qty = Number(qty);
                    for (let i = 0; i < this.state.cart.products.length; i++) {
                        if (
                            this.state.cart.products[i].price_data.product_data.name ===
                            row.name +
                                Object.keys(row.selectedOptions)
                                    .map((option) => {
                                        return ` - ${row.selectedOptions[option]}`;
                                    })
                                    .join("")
                        ) {
                            let cloneArray = [...this.state.cart.products];
                            cloneArray[i] = { ...(cloneArray[i].quantity += qty) };
                            this.setState({ cloneArray }, () => {
                                this.setLocalCart(this.state.cart);
                            });
                            return false;
                        }
                    }
                    row.qty = qty;

                    this.setState(
                        {
                            cart: {
                                ...this.state.cart,
                                products: this.state.cart.products.concat({
                                    price_data: {
                                        currency: this.state.locale.currency,
                                        product_data: {
                                            name:
                                                row.name +
                                                Object.keys(row.selectedOptions)
                                                    .map((option) => {
                                                        return ` - ${row.selectedOptions[option]}`;
                                                    })
                                                    .join(""),
                                            images: row.images.filter((image) => image.variation === row.color).length ? [row.images.filter((image) => image.variation === row.color)[0].src] : [row.images[0].src],
                                        },
                                        unit_amount: row.prices[0],
                                    },
                                    quantity: row.qty,
                                }),
                            },
                        },
                        () => {
                            this.setLocalCart(this.state.cart);
                        }
                    );
                },
            },
            cartState: false,
            menuState: false,
        };
    }

    componentDidMount() {
        fetch("/api/products", { method: "GET" })
            .then((response) => response.json())
            .then((data) =>
                this.setState({
                    products: data.data.documents.sort((a, b) => a.created - b.created),
                })
            );

        fetch("/api/reviews", { method: "GET" })
            .then((response) => response.json())
            .then((data) =>
                this.setState({
                    reviews: data.data.result.reviews,
                    rating: data.data.result.rating
                })
            );
    }

    setLocalCart = (cart) => {
        localStorage.setItem("cart", JSON.stringify(cart));
    };

    toggleDrawer = (drawer) => {
        if (drawer === "menu") {
            this.setState({ menuState: !this.state.menuState, cartState: false });
            if (!this.state.menuState) {
                document.body.classList.add("drawer-open");
            } else {
                document.body.classList.remove("drawer-open");
            }
        } else if (drawer === "cart") {
            this.setState({ cartState: !this.state.cartState, menuState: false });
            if (!this.state.cartState) {
                document.body.classList.add("drawer-open");
            } else {
                document.body.classList.remove("drawer-open");
            }
        } else {
            this.setState({ cartState: false, menuState: false });
            document.body.classList.remove("drawer-open");
        }
    };

    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" render={() => <Shop products={this.state.products} cart={this.state.cart} functions={this.state.functions} toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} cartState={this.state.cartState} locale={this.state.locale} rating={this.state.rating} />} />
                        <Route exact path="/home" render={() => <Landing toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} cartState={this.state.cartState} reviews={this.state.reviews} rating={this.state.rating} />} />
                        <Route exact path="/shop" render={() => <Shop products={this.state.products} cart={this.state.cart} functions={this.state.functions} toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} cartState={this.state.cartState} locale={this.state.locale} rating={this.state.rating} />} />
                        {this.state.products.map((prod, index) => {
                            return <Route key={index} exact path={`/product/${prod.name.toLowerCase().replace(/ /g, "-")}`} render={() => <ProductDetail cart={this.state.cart} functions={this.state.functions} allProducts={this.state.products} product={prod} toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} cartState={this.state.cartState} locale={this.state.locale} />} />;
                        })}
                        <Route exact path="/order/success" render={() => <Success toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} rating={this.state.rating}  />} />
                        <Route exact path="/order/cancel" render={() => <Cancel toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} rating={this.state.rating}  />} />
                        <Route exact path="/terms-and-conditions" render={() => <Terms toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} rating={this.state.rating}  />} />
                        <Route exact path="/privacy" render={() => <Privacy toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} rating={this.state.rating}  />} />
                        <Route exact path="/instructions" render={() => <Instructions products={this.state.products} toggleDrawer={this.toggleDrawer} menuState={this.state.menuState} cart={this.state.cart} rating={this.state.rating}  />} />
                        <Route path="/sitemap.xml" onEnter={() => window.location.reload()} />
                        <Route path=" " component={NotFound} />
                    </Switch>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default withRouter(App);
