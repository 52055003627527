import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { tokens } from "../../css/tokens";
import logo from "../../assets/jm_logo-banner.png";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Section, Container } from "../Elements";
import { ProductListing } from "../ProductListing";
import { CountryModal } from "../Modal";
import Product, { ProductWrapper, SkeletonProduct } from "../Product";
import { SearchField } from "../Form";

import nl_NL from "../../assets/countries/nl_NL.svg";
import eu_EU from "../../assets/countries/eu_EU.svg";
import en_US from "../../assets/countries/en_US.svg";
import xx_XX from "../../assets/countries/xx_XX.svg";

const MobileNone = styled.span`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const Hero = styled.div`
  display: flex;
  background-color: #000;
  height: 300px;
  background-image: url(${(props) => props.backgroundPhoto});
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;

  img{
    cursor: pointer;
  }

  > div{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1{
    font-size: 3.2rem;
    margin: 1.2rem 0;
    padding: 0 0 1.2rem;
    border-bottom: 1px solid currentColor;
  }

  .subtitle{
    margin: 0;
    color: ${tokens.colors.invert};
  }

  ${(props) =>
      props.backdrop &&
      css`
    &::after{
      content:'';
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background-image:linear-gradient(90deg, transparent, rgba(0,0,0,.6), transparent);
      z-index:-1;
    `}
  }
`;

const ShopProducts = ({ cart, products, functions, locale, ...props }) => {
    let history = useHistory();

    const [searchTerm, updateSearch] = useState("");
    const [filtered, filterProds] = useState(products.length);
    const searchBar = useRef();

    const [countryModal, openCountryModal] = useState(!locale.country);
    let selectedCountry = {
        flag: undefined,
        country: undefined,
    };

    useEffect(() => {
        filterProds(products.filter((product) => product.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 || product.id.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0).length);
    }, [products, searchTerm]);

    switch (locale.country) {
        case "nl":
            selectedCountry.flag = nl_NL;
            selectedCountry.country = "Netherlands";
            break;
        case "eu":
            selectedCountry.flag = eu_EU;
            selectedCountry.country = "Europe";
            break;
        case "not-eu":
            selectedCountry.flag = en_US;
            selectedCountry.country = "United States";
            break;
        default:
            selectedCountry.flag = xx_XX;
            selectedCountry.country = "None";
    }

    return (
        <Section style={{ backgroundColor: "#eee", flex: 1 }}>
            <Hero backgroundPhoto={"https://storage.googleapis.com/windy-semiotics-351512.appspot.com/prods/DSC05436-3.jpg"} backdrop>
                <Container>
                    <img alt="logo" src={logo} height={100} onClick={() => history.push("/")} />
                    <h1>Juicy Melons Products</h1>
                    <p className="subtitle">{!!products.length ? filtered : "LOADING"} PRODUCTS</p>
                </Container>
            </Hero>
            <div
                style={{
                    width: "100%",
                    backgroundColor: "#303030",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0.8rem 0",
                    position: "relative",
                    zIndex: 1,
                }}
            >
                <Container
                    style={{
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "1.4rem",
                    }}
                >
                    <SearchField placeholder="Search products" type="text" query={searchTerm} updateQuery={updateSearch} forwardRef={searchBar} className="search" />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <MobileNone>Selected region:</MobileNone>
                        <span
                            onClick={openCountryModal}
                            style={{
                                marginLeft: "0.8rem",
                                cursor: "pointer",
                                display: "flex",
                                gap: "0.8rem",
                                border: "1px solid #666",
                                padding: "4px 6px",
                                borderRadius: "4px",
                            }}
                        >
                            <img src={selectedCountry.flag} alt={selectedCountry.country} width="24" />
                            <MobileNone>{selectedCountry.country}</MobileNone>
                        </span>
                    </div>
                </Container>
            </div>
            <Container
                style={{
                    width: "100%",
                    paddingTop: "1rem",
                    backgroundColor: "#eee",
                    position: "relative",
                }}
            >
                <ProductListing>
                    {products.length
                        ? products.map((product, index) => {
                              if (product.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 || product.id.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
                                  return <Product locale={locale} cart={cart} key={`product_${index}`} data={product} allProducts={products} functions={functions} />;
                              }
                              return null;
                          })
                        : [0, 1, 2].map((index) => {
                              return <SkeletonProduct key={index} />;
                          })}
                    {products.length && !filtered ? (
                        <ProductWrapper>
                            <strong className="h2">No products found</strong>
                            <br />
                            <br />
                            Please clear- or update your search query.
                            <br />
                            <br />
                            If you still can't find what you're looking for, or if you are experiencing other issues please <HashLink to="/home#contact">contact us</HashLink>!
                        </ProductWrapper>
                    ) : undefined}
                </ProductListing>
            </Container>
            {countryModal ? <CountryModal locale={locale} setCountry={functions.setCountry} closeModal={openCountryModal} clearCart={functions.clear} /> : ""}
        </Section>
    );
};

export { ShopProducts };
