import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import nl_NL from "../assets/countries/nl_NL.svg";
import eu_EU from "../assets/countries/eu_EU.svg";
//import en_US from "../assets/countries/en_US.svg";

import { OrderButton, AddToCart } from "./ProductFunctions";
import { Price } from "./Price";
import {
  ModalWrapper,
  ProductImage,
  ProductContent,
  RelatedProduct,
} from "./Product";
import { FormOption } from "./FormOption";
import { OptionsForm } from "./OptionsForm";
import { RadioButton } from "./RadioButton";
import { Button } from "./Form";
import { Note } from "./Elements";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(100, 100, 100, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  overflow: auto;
  z-index: 3;
`;

const StyledModal = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  width: 600px;
  max-width: 90%;
  z-index: 5;
  position: static;

  h3{
    margin-bottom: 2rem;
  }

  &.back{
    position: fixed;
    right: 100vw;
    top: 0;
    transition: margin-right 0.3s;
    background-attachment: scroll;
  }

  img.logo {
    max-width: 100%;
  }

}

@supports (-webkit-touch-callout: none) {
  background-attachment: scroll;
}
`;

const CloseButton = styled.button`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z'/%3E%3C/svg%3E");
  line-height: 0;
  position: absolute;
  right: 0;
  bottom: 100%;
  cursor: pointer;
  height: 3.2rem;
  width: 3.2rem;
  margin-bottom: 1rem;
  background-color: transparent;

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 0 512 512'%3E%3Cpath fill='%23FE7796' d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z'/%3E%3C/svg%3E");
  }
`;

export const RelatedProducts = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;

  &:not(:first-of-type) {
    border-top: 1px solid #ddd;
    padding-top: 2rem;
  }

  h3 {
    width: 100%;
    margin-bottom: 0;
  }
`;

const CountryModal = ({
  country,
  closeModal,
  setCountry,
  locale,
  clearCart,
  ...props
}) => {
  const [region, setRegion] = useState(locale);

  const currencySelect = useRef();
  const countrySelect = useRef();

  const selectRegion = () => {
    closeModal(false);
    setCountry(region);
    localStorage.setItem("locale", JSON.stringify(region));
  };

  return (
    <ModalWrapper>
      <StyledModal {...props}>
        <h3>Please select your region: </h3>

        <RadioButton stretched>
          <input
            defaultChecked={region.country === "nl"}
            type="radio"
            id="netherlands"
            name="region"
            value="nl"
            onChange={(evt) =>
              setRegion({ country: evt.target.value, currency: "eur" })
            }
          />
          <label htmlFor="netherlands">
            <img width="24" src={nl_NL} alt="nl_NL" />
            Netherlands
          </label>
          <span className="select">EUR</span>
        </RadioButton>

        <RadioButton>
          <input
            ref={countrySelect}
            defaultChecked={region.country === "eu"}
            type="radio"
            id="europe"
            name="region"
            value="eu"
            onChange={(evt) => {
              setRegion({
                currency: currencySelect.current.value,
                country: evt.target.value,
              });
            }}
          />
          <label htmlFor="europe">
            <img width="24" src={eu_EU} alt="eu_EU" />
            Europe
          </label>
          <select
            ref={currencySelect}
            defaultValue={locale.currency}
            onChange={(evt) => {
              countrySelect.current.checked = true;
              setRegion({
                currency: evt.target.value,
                country: countrySelect.current.value,
              });
            }}
          >
            <option value="eur">EUR</option>
            <option value="gbp">GBP</option>
          </select>
        </RadioButton>

        {/* <RadioButton stretched>
          <input
            defaultChecked={region.country === "not-eu"}
            type="radio"
            id="world"
            name="region"
            value="not-eu"
            onChange={(evt) =>
              setRegion({ country: evt.target.value, currency: "usd" })
            }
          />
          <label htmlFor="world">
            <img width="24" src={en_US} alt="en_US" />
            United States
          </label>
          <span className="select">USD</span>
        </RadioButton> */}
        <Button
          onClick={() => {
            selectRegion();
            localStorage.removeItem("cart");
            clearCart();
          }}
          disabled={!region.country && !country}
        >
          Select
        </Button>
      </StyledModal>
      <Backdrop />
    </ModalWrapper>
  );
};

const Modal = ({
  locale,
  functions,
  product,
  modal,
  type,
  openModal,
  related,
  ...props
}) => {
  const [valid] = useState([]);
  const [validForm, checkFormValidity] = useState(false);

  const availableOptions = () => {
    let emptyArr = [];
    Object.keys(product.options).map((option, index) => {
      emptyArr = emptyArr.concat(product.options[option].options);
      return false;
    });
    if (!emptyArr.length) {
      checkFormValidity(true);
    }
  };

  //OPTIONS ------------------
  const sortOptions = () =>
    Object.keys(optVal)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = optVal[key];

        return accumulator;
      }, {});

  const checkValidity = (options) => {
    for (let i = 0; i < options.length; i++) {
      if (!options[i]) {
        checkFormValidity(false);
        break;
      } else {
        checkFormValidity(true);
      }
    }
  };

  const [optVal, setOption] = useState({});
  let optionIndex = -1;

  const childToParentTwo = (childdata, elm, option, i) => {
    valid[elm.dataset.index] = true;
    setOption((optVal) => ({
      ...optVal,
      [option]: childdata,
    }));
    checkValidity(valid);
  };
  //END OPTIONS --------------

  useEffect(() => {
    availableOptions();
    sortOptions();
  });

  return (
    <StyledModal {...props}>
      {product ? (
        <>
          <h3>{product.name}</h3>
          <OptionsForm>
            {Object.keys(product.options).map((option, index) => {
              if (product.options[option].options.length) {
                optionIndex += 1;
                valid[optionIndex] = !!valid[optionIndex]
                  ? valid[optionIndex]
                  : false;
                return (
                  <FormOption
                    key={index}
                    childToParent={childToParentTwo}
                    index={optionIndex}
                    product={product.options}
                    name={option}
                    option={product.options[option].options}
                  />
                );
              } else {
                return false;
              }
            })}
          </OptionsForm>
          {/* {product.options.printColor.options.length >= 1 ? <section>
            <ColorSwatches
              type={type}
              childToParent={childToParent}
              colors={product.options.printColor.options}
              title
            />
          </section> : undefined} */}
          {!product.stock.amount ? (
            <Note
              style={{ border: 0, padding: 0 }}
              note={{
                type: "information",
                message:
                  "This product is currently out of stock and will be put on backorder. We will send it as soon as we have stock!",
              }}
            />
          ) : undefined}
          <AddToCart type={type} style={{ gap: "2rem" }}>
            <div style={{ flex: "1 1 100px" }}>
              <Price
                value={product.prices[0]}
                tax={true}
                margin={false}
                currency={locale.currency}
              />
            </div>
            <OrderButton
              style={{ flex: "1 1 auto", width: "auto" }}
              label={!product.stock.amount ? "Backorder" : "Add to cart"}
              cart={functions}
              prod={product}
              color={optVal.printColor} //color
              options={optVal}
              colorList={product.options.printColor.options}
              quantity={true}
              openModal={openModal}
              disabled={!validForm}
            />
          </AddToCart>
        </>
      ) : undefined}
      {related.length ? (
        <RelatedProducts>
          <h3>Related products:</h3>
          {related.map((product, index) => {
            return (
              <RelatedProduct
                key={index}
                vertical={true}
                className="related-product"
                border={true}
              >
                {/* onClick={(e) => { if (!e.target.classList.contains('primary') && !e.target.hasAttribute('data-controls')) { props.history.push({ pathname: `/product/${data.name.toLowerCase().replace(/ /g, '-')}`, state: { stateCart: JSON.stringify(cart) } }) } }}  */}
                <ProductImage className="related-product__image">
                  <img
                    src={product.images[0].src}
                    alt={`${product.images[0].product} ${product.images[0].variation}`}
                  />
                </ProductImage>
                <ProductContent className="related-product__content">
                  <h4>
                    {product.name} -{" "}
                    <Price
                      tax={true}
                      value={product.prices[0]}
                      margin={false}
                      currency={locale.currency}
                      style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                      }}
                    />
                  </h4>
                  <OrderButton
                    cart={functions}
                    prod={product}
                    colorList={product.options.printColor.options}
                    quantity={false}
                    btnColor="green"
                  />
                </ProductContent>
              </RelatedProduct>
            );
          })}
        </RelatedProducts>
      ) : (
        props.children
      )}
      <CloseButton onClick={() => openModal(false)} />
    </StyledModal>
  );
};

export { Modal, CountryModal };
