import React from "react";
import styled, { css } from "styled-components";

const StyledSmall = styled.small`
  font-weight: 400;
  color: #aaa;

  ${props => props.margin && css`
    margin-bottom: 0.4rem;
  `}
`

export const Small = ({ margin, ...props }) => {
  return (
    <StyledSmall margin={margin} {...props}>
        {props.children}
    </StyledSmall>
  );
}
