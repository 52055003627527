import React from "react";
import styled, { css } from "styled-components";

import { Small } from "./Titles";

const StyledPrice = styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    font-family: Montserrat;
    margin-top: 2px;
    ${(props) =>
        props.margin &&
        css`
            margin-bottom: 16px;
        `}
`;

export const FormatPrice = ({ value, currency }) => {
    const curSymbols = {
        usd: "$",
        eur: "€",
        gbp: "£",
    };

    const unitAmount = value ? value[`unit_amount_${currency}`] : undefined;
    const price = unitAmount ? (unitAmount / 100).toFixed(2) : (value / 100).toFixed(2);
    return `${curSymbols[currency]} ${price}`;
};

export const Price = ({ currency, value, uom, tax, margin, smallText, ...props }) => {
    return (
        <StyledPrice margin={margin} style={props.style}>
            <FormatPrice value={value} currency={currency} />
            {tax ? <Small>incl. VAT</Small> : ""}
            {smallText ? <Small margin={margin}>{smallText}</Small> : ""}
        </StyledPrice>
    );
};
