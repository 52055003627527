import React from "react";
import { withRouter } from "react-router";

import styled from "styled-components";
import { tokens } from "../css/tokens.jsx";

import { ColorSwatches, ModalWrapper } from "../components/Product";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Price } from "../components/Price";
import { Cart } from "../components/Cart";
import { OrderButton, AddToCart } from "../components/ProductFunctions";
import { PillContainer, Pill } from "../components/Pills";
import { ImageSlider } from "../components/Slider";
import { Footer } from "../components/Footer";
import { Notes, Note } from "../components/Elements";
import { StockMessage } from "../components/StockMessage.js";

import { Modal, Backdrop, CountryModal } from "../components/Modal";

import { RelatedProduct } from "../components/Product";
import { RelatedProducts } from "../components/Modal";
import { ProductImage, ProductContent } from "../components/Product";

const ProductDetailView = styled.section`
    display: flex;
    min-height: 100%;
    flex-direction: column;

    @media screen and (min-width: ${tokens.breakpoints.medium}) {
        flex-direction: row;

        flex-wrap: wrap;
    }
`;

const ProductDetailImage = styled.div`
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(#22DC20, #1a8218)};
  overflow: hidden;

  @media screen and (min-width: ${tokens.breakpoints.medium}){
    overflow: visible;
    position: relative;
  }
`;

const ProductDetailContent = styled.div`
    flex: 1 1 50%;
    padding: 4rem 4rem 8rem;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
    z-index: 1;
    height: 100vh;
    background-color: #fff;

    section {
        margin-bottom: 4rem;
    }

    h1 {
        color: #000;
        width: 100%;
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 800;
        line-height: 110%;
    }

    h3 {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 800;
        line-height: 110%;
        margin-bottom: 10px;
    }

    @media screen and (min-width: ${tokens.breakpoints.medium}) {
        padding-bottom: 4rem;
        height: auto;
        overflow: visible;
    }
`;

export class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: false,
            modal: false,
            countryModal: true,
        };
    }

    childToParent = (childdata) => {
        this.setState({ color: childdata });
    };

    componentDidMount() {
        setTimeout(window.scrollTo(0, 0), 0);
    }

    countryModalState = (state) => {
        this.setState({ countryModal: state });
    };

    modalFunction = (toggleState) => {
        if (toggleState === false) {
            document.body.classList.remove("drawer-open");
        } else {
            document.body.classList.add("drawer-open");
        }

        this.setState({ modal: toggleState });
    };

    render() {
        const relatedProducts = this.props.allProducts.filter((product) => this.props.product.related.some((related) => related === product.id));

        let price;
        switch (this.props.locale.currency) {
            case "eu":
                price = this.props.product.prices[0].unit_amount_eur;
                break;
            case "usd":
                price = this.props.product.prices[0].unit_amount_usd;
                break;
            case "gbp":
                price = this.props.product.prices[0].unit_amount_gbp;
                break;
            default:
                price = this.props.product.prices[0].unit_amount_eur;
        }

        const formatPrice = (price) => {
            const amount = price.toString();
            const formattedAmount = amount.slice(0, -2) + "." + amount.slice(-2);
            return formattedAmount;
        };

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "item_content",
            ecommerce: {
                currency: this.props.locale.currency,
                value: formatPrice(price),
                item_id: this.props.product.id,
                item_name: this.props.product.name,
                price: formatPrice(price),
                quantity: 1,
            },
        });

        return (
            <>
                <ProductDetailView>
                    <ProductDetailImage>
                        {/* TODO: Use window.matchMedia and check for resize */}
                        <ImageSlider slideNo={this.state.color} slides={this.props.product.images} axis={window.innerWidth > 768 ? "vertical" : "horizontal"} small />
                    </ProductDetailImage>
                    <ProductDetailContent>
                        <Breadcrumbs short={true} path={[{ name: "Back to products" }]} />
                        <section>
                            <h1>{this.props.product.name}</h1>
                            <StockMessage stock={this.props.product.stock.amount} style={{ margin: "1rem 1rem 1rem 0" }} />
                            <span style={{ color: tokens.colors.gray, whiteSpace: "nowrap" }}>#{this.props.product.id}</span>
                        </section>
                        <section>
                            <h3>Description</h3>
                            {this.props.product.description}
                            <Notes>
                                {this.props.product.notes.map((note, index) => {
                                    return <Note note={note} key={index} />;
                                })}
                            </Notes>
                        </section>
                        {relatedProducts.length ? (
                            <section
                                style={{
                                    backgroundColor: "#eee",
                                    margin: "0 -4rem 4rem",
                                    padding: "2rem 4rem",
                                }}
                            >
                                <RelatedProducts>
                                    <h3 style={{ marginBottom: 0 }}>Related products:</h3>
                                    {relatedProducts.map((product, index) => {
                                        return (
                                            <RelatedProduct
                                                onClick={(e) => {
                                                    this.props.history.push({
                                                        pathname: `/product/${product.name.toLowerCase().replace(/ /g, "-")}`,
                                                        state: {
                                                            stateCart: JSON.stringify(this.props.cart),
                                                        },
                                                        relatedProducts: relatedProducts,
                                                    });
                                                }}
                                                style={{ alignItems: "center", cursor: "pointer" }}
                                                key={index}
                                                vertical={true}
                                                className="related-product"
                                            >
                                                <ProductImage className="related-product__image">
                                                    <img style={{ maxWidth: "10rem" }} src={product.images[0].src} alt={`${product.images[0].product} ${product.images[0].variation}`} />
                                                </ProductImage>
                                                <ProductContent className="related-product__content" style={{ padding: "0 1.6rem" }}>
                                                    <h4 style={{ padding: 0 }}>
                                                        {product.name} -{" "}
                                                        <Price
                                                            tax={true}
                                                            value={product.prices[0]}
                                                            margin={false}
                                                            currency={this.props.locale.currency}
                                                            style={{
                                                                display: "inline-flex",
                                                                flexDirection: "row",
                                                                alignItems: "baseline",
                                                            }}
                                                        />
                                                    </h4>
                                                </ProductContent>
                                            </RelatedProduct>
                                        );
                                    })}
                                </RelatedProducts>
                            </section>
                        ) : undefined}
                        {this.props.product.package_contents.length >= 1 ? (
                            <section>
                                <h3>Package contents</h3>
                                <ul style={{ padding: "0 0 0 1.6rem" }}>
                                    {this.props.product.package_contents.map((content, index) => {
                                        return <li key={index}>{content}</li>;
                                    })}
                                </ul>
                            </section>
                        ) : null}
                        <section>
                            {this.props.product.material.length > 0 ? (
                                <>
                                    <h3>Material</h3>
                                    <PillContainer>
                                        {this.props.product.material.map((material, index) => {
                                            return <Pill key={index}>{material}</Pill>;
                                        })}
                                    </PillContainer>
                                </>
                            ) : (
                                ""
                            )}
                        </section>
                        <section>
                            {this.props.product.options.printColor.options.length > 0 ? (
                                <>
                                    <h3>Colours</h3>
                                    <ColorSwatches colors={this.props.product.options.printColor.options} />
                                </>
                            ) : (
                                ""
                            )}
                        </section>
                        <AddToCart sticky style={{ marginBottom: "4rem", gap: "2rem" }}>
                            <div style={{ flex: 0, whiteSpace: "nowrap" }}>
                                <Price value={this.props.product.prices[0]} tax={true} currency={this.props.locale.currency} />
                            </div>
                            <OrderButton cart={this.props.functions} label="Add to cart" prod={this.props.product} onClick={() => this.modalFunction(!this.state.modal)} style={{ transform: "none" }} />
                        </AddToCart>
                        {this.props.product.video ? (
                            <section>
                                <h3>Instructions</h3>
                                <iframe width="100%" height="315" src={`${this.props.product.video}?modestbranding=1&rel=0`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </section>
                        ) : (
                            ""
                        )}
                    </ProductDetailContent>
                    <Cart toggleDrawer={this.props.toggleDrawer} cartState={this.props.cartState} cart={this.props.cart} functions={this.props.functions} locale={this.props.locale} />
                    <Footer rating={this.props.rating} style={{ flex: "1 1 100%", minWidth: "100%" }} />
                </ProductDetailView>
                {this.state.modal ? (
                    <ModalWrapper>
                        <Backdrop onClick={() => this.modalFunction(false)} />
                        <Modal type="select" functions={this.props.functions} openModal={this.modalFunction} related={relatedProducts} product={this.props.product} modal={this.state.modal} locale={this.props.locale} />
                    </ModalWrapper>
                ) : (
                    ""
                )}
                {this.state.countryModal && !this.props.locale.country ? <CountryModal locale={this.props.locale} setCountry={this.props.functions.setCountry} closeModal={this.countryModalState} /> : undefined}
            </>
        );
    }
}

export default withRouter(ProductDetail);
