import React, { useEffect, useRef } from "react";
import TinySlider from "tiny-slider-react";
import styled, { css } from "styled-components";
import { tokens } from "../css/tokens.jsx";

const StyledImageSlider = styled.div`
  position: relative;

  ${props => props.axis === 'vertical' && css`
    @media screen and (min-width: ${tokens.breakpoints.medium}){
      position: static;
      width: 100%;
      height: 100%;
    }
  `}

  .tns-outer{
    ${props => props.axis === 'vertical' && css`
      @media screen and (min-width: ${tokens.breakpoints.medium}){
        height: 100%;
        width: 100%;
      }
    `}

    .tns-slider {
      display: flex;
      align-items: center;

      ${props => props.axis === 'vertical' && css`
        @media screen and (min-width: ${tokens.breakpoints.medium}){
          display: block;
          margin-top: calc((100vh - (50vw * 0.5625)) / 2);
        }
      `}
    }

    .tns-inner {
      background-color: #eee;

      ${props => props.axis === 'vertical' && css`
        @media screen and (min-width: ${tokens.breakpoints.medium}){
          overflow: hidden;
          height: 100%;
          position: fixed;
          width: 50%;
          top: 0;
        }
      `}
    }

    .tns-visually-hidden {
      display: none;
    }

    .tns-nav,
    .tns-controls {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      z-index: 1;
      pointer-events: none;

      ${props => props.axis === 'vertical' && css`
        @media screen and (min-width: ${tokens.breakpoints.medium}){
          top: calc(100vh - 6rem);
        }
      `}

      button {
        background-color: transparent;
        border: 2px solid ${tokens.colors.pink};
        opacity: 0.6;
        font-size: ${tokens.fontSize.small};
        pointer-events: all;
        min-height: 5rem;

        &:hover {
          opacity: 0.85;
        }

        &.tns-nav-active {
          opacity: 1;
          background-color: ${tokens.colors.white};
        }
      }
    }

    .tns-controls {
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);

      ${props => props.axis === 'vertical' && css`
        @media screen and (min-width: ${tokens.breakpoints.medium}){
          position: sticky;
          height: 100vh;
          top: 0;
          flex-direction: column;
          transform: none;
        }

        button{
          height: calc((100vh - (50vw * 0.5625)) / 2);
          
          &[data-controls="prev"]{
            position: sticky;
            top: 0;
            
            svg{
              transform: rotate(90deg);
            }
          }
          &[data-controls="next"] svg{
            transform: rotate(90deg);
          }
        }
      `}

      button {
        border: 0;
        font-size: 1.4rem;
        background-color: #fff;
        border-radius: 0;
        color: ${tokens.colors.pink};
        line-height: 0;

        svg {
          width: 1.4rem;
          pointer-events: none;

          ${props => props.axis === 'vertical' && css`
            height: 100%;
            max-height: 6rem;
            width: auto;
          `}
        }
      }
    }

    .tns-nav {
      justify-content: center;
      bottom: ${tokens.spacing.s3};

      ${props => props.axis === 'vertical' && css`
        @media screen and (min-width: ${tokens.breakpoints.medium}){
          bottom: auto;
          position: sticky;
        }
      `}

      button {
        border-radius: 50%;
        width: 1.4rem;
        height: 1.4rem;
        padding: 0;
        margin: ${tokens.spacing.s2};
      }
    }

    img {
      width: 100%;
      position: relative;
      left: 0;

      &:not(.tns-slide-active){
        opacity: 0.3;
      }
    }
  }
`;

export const ImageSlider = ({ slides, small, axis, slideNo, ...props }) => {
  const sliderInstance = useRef();

  const settings = {
    slideBy: 1,
    mouseDrag: true,
    controls: true,
    nav: !!small ? false : true,
    center: true,
    controlsText: [
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>',
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>',
    ],
    axis: axis || 'horizontal',
  }

  useEffect(() => {
    if (slideNo !== false) {
      //TODO: Refactor to react
      let slide = document.querySelector(`img[data-variant="${slideNo}"]`)?.dataset.index;
      sliderInstance.current.slider.goTo(slide);
    }

  }, [small, axis, slideNo]);

  return (
    <StyledImageSlider axis={axis}>
      <TinySlider settings={settings} ref={sliderInstance}>
        {slides.map((slide, index) => {
          return <img src={slide.src} data-variant={slide.variation} data-index={index} key={index} alt={`${slide.product} ${slide.variation}`} />;
        })}
      </TinySlider>
    </StyledImageSlider>
  );
};
