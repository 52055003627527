import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import logo from "../assets/jm_logo-banner.png";
import placeholder from "../assets/banner2.jpg";

import { Container, Section } from "./Elements";
import { Button } from "./Form";

const StyledHeader = styled.div`
  background-image: url(${placeholder});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;

  &.back{
    position: fixed;
    right: 100vw;
    top: 0;
    transition: margin-right 0.3s;
    background-attachment: scroll;
  }

  img.logo {
    max-width: 100%;
  }
}

@supports (-webkit-touch-callout: none) {
  background-attachment: scroll;
}
`;
const Header = ({ ...props }) => {
    let history = useHistory();

    return (
        <Section id="top">
            <StyledHeader {...props}>
                <Container style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", paddingBottom: "8rem" }}>
                    <img alt="logo" src={logo} className="logo" />
                    <Button
                        className="primary-new"
                        onClick={() => {
                            history.push("/shop");
                        }}
                        style={{ position: "absolute", bottom: "9rem", fontSize: "1.8rem" }}
                    >
                        <span>VISIT OUR SHOP NOW</span>
                    </Button>
                </Container>
            </StyledHeader>
        </Section>
    );
};

export { Header };
