import React from "react";
import melonsImg from "../../assets/jm_kiki.jpg";
import logo from "../../assets/jm_logo-banner.png";
import { useHistory } from "react-router-dom";

import { Section, Container } from "../Elements";
import { Hero } from "./ShopProducts";
import { Footer } from "../Footer";
import { MainMenu } from "../MainMenu";

const Cancel = ({ toggleDrawer, menuState }) => {
  let history = useHistory();

  return (
    <Section style={{ backgroundColor: '#fff' }}>
      <MainMenu toggleDrawer={toggleDrawer} menuState={menuState} />
      <Hero backgroundPhoto={melonsImg} style={{ backgroundPosition: 'center 70%' }} backdrop={false} />
      <img alt="logo" src={logo} height={100} style={{
        position: 'relative',
        zIndex: 2,
        left: '50%',
        transform: 'translateX(-50%)',
        margin: '2rem 0 0',
        cursor: 'pointer'
      }} onClick={() => history.push("/")} />
      <Container style={{ padding: '3rem 0', backgroundColor: '#fff', position: 'relative', zIndex: 2 }}>
        <h1>Oops...</h1>
        <p>
          Your order has been cancelled. If this was not intentional, please try again.
        </p>
        <p>
          If you're still not able to place your order please contact us at: <a href="mailto:juicymelons.shop@gmail.com">juicymelons.shop@gmail.com</a>!
        </p>
        <h2 style={{ marginTop: '4rem' }}>Let us know what happened</h2>
        <iframe title="Juicy Melons Feedback Form" src="https://docs.google.com/forms/d/e/1FAIpQLSe1PU-fjzH6AL4GSrhjPjUqJg90D5ZqrdNOPryDaslmMBT5PQ/viewform?embedded=true" style={{ maxWidth: '100%', marginLeft: '-3%' }} width="700" height="900" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
      </Container>
      <Footer rating={this.props.rating} />
    </Section >
  );
};

export { Cancel };
