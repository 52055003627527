import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import melonsImg from "../../assets/jm_kiki.jpg";
import logo from "../../assets/jm_logo-banner.png";
import { tokens } from "../../css/tokens.jsx";
import styled from "styled-components";

import { Section, Container } from "../Elements";
import { Hero } from "./ShopProducts";
import { Footer } from "../Footer";
import { FormatPrice } from "../Price";
import { Small } from "../Titles";
import { MainMenu } from "../MainMenu";

export const OrderTable = styled.table`
    padding: 1rem;
    border-spacing: 0;
    table-layout: fixed;
    flex: ${(props) => (props.small ? 1 : "unset")};
    width: ${(props) => (props.small ? "auto" : "100%")};
    background-color: ${(props) => (props.background ? "#eee" : tokens.colors.white)};
    border: 1px solid ${tokens.colors.grayLight};

    small {
        font-size: ${tokens.fontSize.smallest};
    }
`;

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Success = ({ toggleDrawer, menuState }) => {
    const [order, setOrder] = useState([]);
    const [session, setSession] = useState({});
    const query = useQuery();

    useEffect(() => {
        let orderInfo;
        async function getOrderData() {
            const sessionId = query.get("session_id");
            const response = await fetch(`/api/order/success?session_id=${sessionId}`);
            orderInfo = await response.json();
            let orderData = orderInfo.data.data;
            let sessionData = orderInfo.session;

            setOrder(orderData);
            setSession(sessionData);

            let locale = JSON.parse(localStorage.getItem("locale"));
            let currency = locale.currency;

            const formatPrice = (price) => {
                return Number(price) / 100;
            };

            let value = 0;
            const items = [];

            orderData.forEach((item) => {
                value += formatPrice(item.price.unit_amount) * item.quantity;

                items.push({
                    item_name: item.description,
                    price: formatPrice(item.price.unit_amount),
                    quantity: item.quantity,
                });
            });

            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

            window.dataLayer.push({
                event: "purchase",
                ecommerce: {
                    transaction_id: sessionId,
                    value: value,
                    currency: currency,
                    items: items,
                },
            });
        }
        getOrderData();
    }, [query]);

    return (
        <Section style={{ backgroundColor: "#fff" }}>
            <MainMenu toggleDrawer={toggleDrawer} menuState={menuState} />
            <Hero backgroundPhoto={melonsImg} style={{ backgroundPosition: "center 70%" }} backdrop={false} />
            <img
                alt="logo"
                src={logo}
                height={100}
                style={{
                    position: "relative",
                    zIndex: 2,
                    left: "50%",
                    transform: "translateX(-50%)",
                    margin: "2rem 0 0",
                }}
            />
            <Container
                style={{
                    padding: "3rem 0",
                    backgroundColor: "#fff",
                    position: "relative",
                    zIndex: 2,
                }}
            >
                <h1>Thank you for your order!</h1>
                <p>
                    You order has been received! We try to get it delivered to you as soon as possible!
                    <br />
                    If you have any questions or concerns don't hesitate to contact us at: <a href="mailto:juicymelons.shop@gmail.com">juicymelons.shop@gmail.com</a>!
                </p>

                <h2 style={{ marginTop: "4rem" }}>Order details</h2>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "2rem",
                        marginTop: "2rem",
                    }}
                >
                    <OrderTable background small>
                        <thead>
                            <tr>
                                <td>
                                    <strong>Name:</strong>
                                </td>
                                <td>{session.customer_details?.name}</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Email:</strong>
                                </td>
                                <td>{session.customer_details?.email}</td>
                            </tr>
                            {/* <tr>
                <td><strong>Date:</strong></td><td>XX-XX-XXXX</td>
              </tr> */}
                            <tr>
                                <td>
                                    <strong>Amount:</strong>
                                </td>
                                <td>
                                    <FormatPrice value={session.amount_total} currency={session.currency} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Payment status:</strong>
                                </td>
                                <td>{session.payment_status}</td>
                            </tr>
                        </thead>
                    </OrderTable>

                    <OrderTable background small>
                        <thead>
                            <tr>
                                <td>
                                    <strong>Address:</strong>
                                </td>
                                <td>
                                    {session.customer_details?.address.line1}
                                    <br />
                                    {session.customer_details?.address.line2}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>City:</strong>
                                </td>
                                <td>
                                    {session.customer_details?.address.city} ({session.customer_details?.address.country})
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Postal code:</strong>
                                </td>
                                <td>{session.customer_details?.address.postal_code}</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>State:</strong>
                                </td>
                                <td>{session.customer_details?.address.state || "-"}</td>
                            </tr>
                        </thead>
                    </OrderTable>

                    <OrderTable>
                        <thead>
                            <tr>
                                {/* <th align="left">Image</th> */}
                                <th align="left" colSpan={3}>
                                    Product
                                </th>
                                <th align="left">Price</th>
                            </tr>
                        </thead>
                        {order.map((product, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        {/* <td valign="top">IMG</td> */}
                                        <td valign="top" colSpan={3}>
                                            {product.description.replace("false", "")}
                                            <br />
                                            <Small>Amount: {product.quantity}</Small>
                                        </td>
                                        <td valign="top">
                                            <FormatPrice value={product.amount_total} currency={session.currency} />
                                            <Small>
                                                <FormatPrice value={product.price.unit_amount} currency={session.currency} />
                                            </Small>
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </OrderTable>
                </div>
                <table
                    style={{
                        width: "100%",
                        padding: "1rem",
                        borderSpacing: 0,
                        tableLayout: "fixed",
                        fontWeight: tokens.fontWeight.bold,
                    }}
                >
                    <tbody>
                        <tr>
                            <td colSpan={3} align="right" style={{ paddingRight: "2rem" }}>
                                Order total:
                            </td>
                            <td>
                                <FormatPrice value={session.amount_total} currency={session.currency} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Container>
            <Footer rating={this.props.rating} />
        </Section>
    );
};

export { Success };
