import React from "react";
import styled from "styled-components";
import { tokens } from '../css/tokens';

const StyledPaymentIcon = styled.span`
  font-size: 0;
  border: 1px solid ${tokens.colors.gray};
  color: ${tokens.colors.gray};
  margin: 0 1rem 1rem 0;
  padding: ${tokens.spacing.s3};
  border-radius: 4px;
  min-width: 8rem;
  display: inline-flex;
  justify-content: center;

  svg{
    height: 3rem;
  }
`

export const PaymentIcon = ({ ...props }) => {
  return (
    <StyledPaymentIcon>
      {props.children}
    </StyledPaymentIcon>
  );
}
