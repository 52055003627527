import styled from "styled-components";

export const Pill = styled.li`
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
`

export const PillContainer = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  gap: 1rem;
`
