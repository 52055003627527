import React from "react";
import styled from "styled-components";

import { OptionSelect } from "./Product";

const StyledOption = styled.div`
  flex: 1 1 50%;
`

export const FormOption = ({ childToParent, index, product, option, name, ...props }) => {
  return (
    <StyledOption>
      <strong>
        {name.split(/(?=[A-Z])/).map((optionNamePart) => {
          return optionNamePart.replace(/^[a-z]/, s => s.toUpperCase())
        }).join(' ')}
      </strong>
      <OptionSelect required={true} data-index={index} childToParent={(e, elm) => childToParent(e, elm, name, index)} option={option} placeholder={product[name].placeholder} />
    </StyledOption>
  );
}
