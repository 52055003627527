import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { tokens } from "../css/tokens";

import { Small } from "./Titles";

const BreadcrumbDivider = styled.span`
    color: #dddddd;

    svg {
        width: 0.6rem;
        margin: 0 0.6rem;
    }
`;

const BreadcrumbItem = styled.a`
    cursor: pointer;
    display: inline-block;

    &:not(:hover) {
        color: inherit;
    }

    svg {
        width: 1.2rem;
        height: 1.2rem;
    }

    &.breadcrumb__back {
        display: inline-flex;
        align-items: center;
        margin-bottom: ${tokens.spacing.s3};
        padding: ${tokens.spacing.s2} ${tokens.spacing.s3};
        border: 1px solid currentColor;
        opacity: 0.4;
        svg {
            width: 1.4rem;
            height: 1.4rem;
            margin-right: ${tokens.spacing.s2};
        }
        &:hover {
            opacity: 1;
        }
    }
`;
const Breadcrumbs = ({ path, short, ...props }) => {
    let history = useHistory();

    return (
        <>
            {short ? (
                <BreadcrumbItem onClick={() => history.push("/shop")} className="breadcrumb__back">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                    {path[0].name}
                </BreadcrumbItem>
            ) : (
                <Small>
                    <BreadcrumbItem onClick={() => history.push("/")}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path fill="currentColor" d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z" />
                        </svg>
                    </BreadcrumbItem>
                    {path.map((item, index) => {
                        return (
                            <span key={index}>
                                <BreadcrumbDivider>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                                    </svg>
                                </BreadcrumbDivider>
                                <BreadcrumbItem onClick={() => history.goBack()}>{item.name}</BreadcrumbItem>
                            </span>
                        );
                    })}
                </Small>
            )}
        </>
    );
};

export { Breadcrumbs };
