import starHalf from "../assets/star_half.svg";
import starFull from "../assets/star_full.svg";
import starNotFilled from "../assets/star_not_filled.svg";
import React from "react";

const getStarType = (index, roundedRating) => {
    const starStatuses = [starNotFilled, starHalf, starFull];
    const starIndex = isHalf(index, roundedRating) ? 1 : isFull(index, roundedRating) ? 2 : 0;
    return starStatuses[starIndex];
};

const isHalf = (index, roundedRating) => index + 0.5 === roundedRating;
const isFull = (index, roundedRating) => index + 1 <= roundedRating;

const StarRating = ({ rating }) => {
    const roundedRating = Math.round(rating * 2) / 2;
    const stars = [];

    for (let i = 0; i < 5; i++) {
        const starType = getStarType(i, roundedRating);
        stars.push(<img key={i} alt={starType} src={starType} />);
    }

    return <p className="star-rating">{stars}</p>;
};


export {StarRating}