import React from "react";
import styled from "styled-components";

const Dot = styled.div`
  font-size: 1.2rem;
  background-color: red;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
`

export const NotificationDot = ({ ...props }) => {
  return (
    <Dot>{props.children}</Dot>
  );
}
